import React from "react";
import { Link } from "react-router-dom";
import LogoCarousel from "../components/LogoCarousel";
import Personal from '../imgs/Personalfoto_2024_min.jpg';
import Jorgen from '../imgs/personal-cirkel-jorgen.jpg';
import Lena from '../imgs/personal-cirkel-lena.jpg';
import Fredrik from '../imgs/personal-cirkel-fredrik.jpg';
import Viktor from '../imgs/personal-cirkel-viktor.jpg';
import Kerstin from '../imgs/personal-cirkel-kerstin.jpg';
import Yvonne from '../imgs/personal-cirkel-yvonne.jpg';
import Tobias from '../imgs/personal-cirkel-tobbe.jpg';
import Marcus from '../imgs/personal-cirkel-mackie.jpg';
import Joakim from '../imgs/personal-cirkel-joakim.jpg';
import Johanna from '../imgs/personal-cirkel-johanna.jpg';
import SocialtEngagemantCarousel from "../components/SocialtEngagemantCarousel";
const About = () => {

    return (
    <>
     
            <div className="container mx-auto">
            <nav className="flex px-10 py-5" aria-label="Breadcrumb">
                <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                    <li className="inline-flex items-center dark:text-white">
                        <Link className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
                            <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                            </svg>
                            Hem
                        </Link>
                    </li>
                    <li aria-current="page" className='dark:text-gray-200'>
                        <div className="flex items-center">
                            <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-gray-200 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                            <Link to="/om-foretaget">Om Företaget</Link>
                        </div>
                    </li>
                </ol>
            </nav>
            <h1 className="text-3xl md:text-4xl font-extrabold dark:text-white py-5 md:pl-10 text-center">Om GastronomiLeverantören
            </h1>
            <div className="flex md:flex-row flex-col flex-center align-center justify-center items-center gap-8 p-10">
           
            <div className=" w-full text-center">
                <h1 className="text-2xl p-2">God smak är den bästa gåvan!</h1>
                <p className="p-2">GastronomiLeverantören är en av Sveriges ledande grossister av delikatessprodukter till specialbutiker och food service. Under våra 29 år i branschen har vi tack vare vår erfarenhet och kunskap byggt upp ett brett och ständigt ökande sortiment av unika premiumprodukter av hög kvalitet. Gärna ekologiskt men framför allt utan tillsatser.</p>
            <p className="p-2">Vår styrka är ett heltäckande sortiment av delikatessprodukter i tider då de flesta företag önskar reducera antalet leverantörer. Vårt sortiment lagerförs i eget lager i Ängelholm, varifrån vi dagligen levererar till våra matintresserade återförsäljare. Våra kunder är allt från delikatessbutiker, caféer, restauranger och inredningsbutiker till blomsterhandeln och frisörer.
            God smak är den bästa gåvan!</p></div>
            </div>
            <h1 className="text-4xl font-extrabold dark:text-white py-5 pl-10 text-center">Våra varumärken:</h1>
<LogoCarousel />
<h1 className="text-4xl font-extrabold dark:text-white pt-10 py-2 pl-8 mx-auto text-center">Lena & Jörgen</h1>
<p className="p-8 mx-auto text-center">Det är vi som äger GastronomiLeverantören. Vi drivs av en stor passion för det vi gör och vi går till jobbet med ett leende. Vår vardag är fylld av god smak och arbetsglädje. Vi omger oss med duktiga och kreativa leverantörer, härlig personal och fantastiska kunder. Vi inspireras av människor och möten och vi blir glada när du hör av dig.</p>
<div className="grid grid-cols-1 md:grid-cols-2 container gap-10 items-center mx-auto text-center align-center justify-center">
    <div className="w-auto mx-auto break-words	">
        <img src={Jorgen} alt="placeholder" className="max-w-96 w-auto" />
        <h2>Jörgen Jeppsson</h2>
        <p className="font-bold">CEO/Owner</p>
        <a href="mailto:j.jeppsson@gastronomileverantoren.se">j.jeppsson@gastronomileverantoren.se</a>
    </div>
    <div className="w-auto mx-auto break-words	">
        <img src={Lena} alt="placeholder"  className="max-w-96 w-auto" />
        <h2>Lena Jeppsson</h2>
        <p className="font-bold">CFO/Owner</p>
        <a href="mailto:lena.jeppsson@gastronomileverantoren.se">lena.eppsson@gastronomileverantoren.se</a>
    </div>
</div>
<h1 className="text-4xl font-extrabold text-center pt-10 pb-10">Vår personal</h1>
<div className="grid grid-cols-2 md:grid-cols-4 items-start gap-10 mx-5 text-center text-wrap">
<div className="break-words	"><img className="object-contain w-full mx-auto" src={Fredrik} alt="placeholder" />
        <h2>Fredrik Persson</h2>
        <p className="font-bold">Lagerchef/Inköp</p>
        <a href="mailto:fredrik@gastronomileverantoren.se">fredrik@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src={Viktor}alt="placeholder" />
        <h2>Viktor Nilsson</h2>
        <p className="font-bold">Marknadsföring/IT/Web-admin</p>
        <a className="text-wrap" href="mailto:viktor@gastronomileverantoren.se">viktor@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src={Kerstin} alt="placeholder" />
        <h2>Kerstin Overgaard</h2>
        <p className="font-bold">Sälj</p>
        <a href="mailto:kerstin@gastronomileverantoren.se">kerstin@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-fullmx-auto" src={Yvonne} alt="placeholder" />
        <h2>Yvonne Nilsson</h2>
        <p className="font-bold">Sälj</p>
        <a href="mailto:yvonne@gastronomileverantoren.se">yvonne@gastronomileverantoren.se</a></div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src={Tobias} alt="placeholder" />
        <h2>Tobias Malmberg</h2>
        <p className="font-bold">Lager</p>
      </div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src={Marcus} alt="placeholder" />
        <h2>Marcus Siltberg</h2>
        <p className="font-bold">Lager</p>
       </div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src={Joakim} alt="placeholder" />
        <h2>Joakim Thelin</h2>
        <p className="font-bold">Lager</p>
        </div>

<div className="break-words	"><img className="object-contain w-full mx-auto" src={Johanna} alt="placeholder" />
        <h2>Johanna Spetsmark</h2>
        <p className="font-bold">Produktkontroll</p>
        <a href="mailto:johanna.spetsmark@gastronomileverantoren.se">johanna.spetsmark@gastronomileverantoren.se</a></div></div>

        <div className="flex gap-8 p-10 ">
            <div className="md:w-2/3 w-full">
            <h1 className="text-4xl p-2 font-bold">Certifikat</h1>
          
               
                <p className="p-2">Här på GastronomiLeverantören jobbar vi aktivt med produktsäkerhet och säker livsmedelshantering. Vi är såklart certifierade för att du som kund ska känna dig trygg. Vi är också en ekonomiskt trygg samarbetspartner och innehar högsta kreditvärdighet.</p>
           </div>
           </div>
           <div className="flex w-2/3 md:flex-row flex-col flex-items-center md:w-2/3 w-full  gap-10 justify-center items-center">
           <div className="flex-col align-center text-center  items-center justify-center">
           <embed src="https://gastronomileverantoren.se/wp-content/uploads/2023/01/Certifikat-IP-Grund-IP-Livsmedel-Grundcertifiering-2022.1-2022.pdf" width="100%" height="450px" frameborder="0" scrolling="auto" />
            <button className="m-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Ladda ned PDF</button>
            
            </div>
            <div className="flex-col align-center text-center  items-center justify-center">
            <embed src="https://gastronomileverantoren.se/wp-content/uploads/2023/01/Certifikat-Import-EU-2022.pdf" width="100%" height="450px" frameBorder="0" scrolling="auto" />
            <button className="m-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Ladda ned PDF</button>
            
            </div>
            <div className="flex-col align-center text-center  items-center justify-center">
            <embed src="https://www.gastronomileverantoren.se/wp-content/uploads/2024/07/SE-EKO-04.752-0007309.2023.002-Gastronomi-Leverantoren-AB-Angelholm.pdf" width="100%" height="450px" frameBorder="0"  />
            <button className="m-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">Ladda ned PDF</button>
            
            </div>
            
            </div>
         <div className=" gap-8 p-10">
<h1 className="text-4xl my-8" > Socialt Engagemang</h1>
<p>Vi brinner för människors lika värde och vi värnar om barn och ungas välmående och rätt till meningsfull sysselsättning.</p>
         </div>
         <SocialtEngagemantCarousel />
</div>

    </>
    

);
};

export default About;