// Uppdatera Category.js för att hämta och rendera underkategorier
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useProductContext } from '../../context/ProductContext';
import { AiOutlineArrowRight, AiOutlineRight } from 'react-icons/ai';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTreeChristmas, faNewspaper, faGift  } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';
import { useUserContext } from '../../context/userContext';
import { Link, useLocation } from 'react-router-dom';

const Category = ({handleChange, onClick, isDesktop}) => {
  const navigate = useNavigate();
  const { user} = useUserContext();
  const { mainCategories, fetchSubCategories } = useProductContext();
  const [selectedMainCategory, setSelectedMainCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
const  location = useLocation();

// Funktion för att stänga subCategory-menyn
const closeSubCategoryMenu = () => {
  setSelectedMainCategory(null);
  setSubCategories([]);
};

  // Kolla om vi navigerar bort från CategoryPage eller till en produktsida under "cat"
useEffect(() => {
  const isCategoryPage = location.pathname.startsWith('/cat');
  const isProductDetailPage = location.pathname.includes('product');
console.log(location.pathname);
  // Om vi inte är på en kategorisida eller om vi är på en produktsida, stäng menyn
  if (!isCategoryPage || isProductDetailPage || isCategoryPage && isProductDetailPage ) {
      closeSubCategoryMenu();

  

  }



}, [location.pathname]);

  const handleMainCategoryClick = async (mainCategory) => {
    setSelectedMainCategory(mainCategory);
  console.log('main cat clicked');
  handleChange(); // Kör handleChange om det behövs för andra ändamål
  if (!isDesktop) {
    onClick();
  }
    if (mainCategory.slug === 'klippvaror') {
      navigate('/klippvaror'); // Navigera direkt till Klippvaror
      return;
    }

      // Rensa searchParams endast om det behövs
  if (searchParams.get('subCategory')) {
    setSearchParams({}); // Rensar alla parametrar
  }

    // Om underkategorierna finns i mainCategory direkt, sätt dem utan att göra ett nytt anrop
    if (mainCategory.subCategories && mainCategory.subCategories.length > 0) {
         // Logga underkategorierna för att se om de är tillgängliga
  console.log('Underkategorier:', mainCategory.subCategories);
      setSubCategories(mainCategory.subCategories);
    
    } else {
      setSubCategories([]); // Rensar gamla underkategorier om kategorin saknar egna
    }
  
     
  // Navigera endast om det inte finns någon subkategori vald i URL
  if (location.pathname.includes('product')) {
    navigate(`/cat/${mainCategory.slug}`);
  }
    handleChange(); // Kör handleChange om det behövs för andra ändamål
    if (!isDesktop) {
      onClick();
    }
  // Navigera endast om det inte finns någon subkategori vald i URL
  if (!window.location.pathname.includes(mainCategory.slug)) {
    navigate(`/cat/${mainCategory.slug}`);
  }
    handleChange(); // Kör handleChange om det behövs för andra ändamål
    if (!isDesktop) {
      onClick();
    }
  };
  const handleSubCategoryClick = (mainCategory, subCategory, event) => {
    event.stopPropagation(); // Förhindra att klicket triggar huvudkategorins onClick console.log('Underkategori klickad:', subCategory.slug);
  
    // Uppdatera URL-parametern för underkategori
    setSearchParams({ subCategory: subCategory.slug });
  
    // Navigera till huvudkategori + underkategori
    navigate(`/cat/${mainCategory.slug}?subCategory=${subCategory.slug}`);

    handleChange(); // Kör handleChange om det behövs för andra ändamål
      if (!isDesktop) {
        onClick();
      }

  };
  
  const categorySortOrder = {
    "Nyheter": 1,
    "Säsong": 2,
    "Klippvaror": 3, // Placera Klippvaror efter Säsong
    "Present": 4,
    "Antipasto": 5,
    "Kex, bröd & Kakor": 6,
    "Olivolja, balsamico & vinäger": 7,
    "Pasta, ris & soppor": 8,
    "Kryddor, salt & smaksättare": 9,
    "Såser, dressing & BBQ": 10,
    "Marmelad, honung & curd": 11,
    "Varma drycker": 12,
    "Dryck": 13,
    "Chips & snacks": 14,
    "Konfekt": 15
  };
  
  // Lägg till en temporär Klippvaror-kategori
  const updatedMainCategories = [
    ...mainCategories,
    ...(user ? [{ id: 'klippvaror', description: 'Klippvaror', slug: 'klippvaror' }] : [])
  ];

  const sortedMainCategories = [...updatedMainCategories]
  .filter(category => {
    // Dölj "Säsong" och "Nyheter" om användaren inte är inloggad
    if (!user && (category.description === "Säsong" || category.description === "Nyheter")) {
      return false;
    }
    return true;
  })
  .sort((a, b) => {
    const orderA = categorySortOrder[a.description] || 999;
    const orderB = categorySortOrder[b.description] || 999;
    return orderA - orderB;
  });


    // Funktion för att returnera ikoner för specifika kategorier
    const getCategoryIcon = (categoryDescription) => {
      switch (categoryDescription) {
        case 'Nyheter':
          return <FontAwesomeIcon icon={faNewspaper} className="text-slate-900" />;
        case 'Säsong':
          return <FontAwesomeIcon icon={faTreeChristmas} className="text-green-800" />;
        case 'Present':
          return <FontAwesomeIcon icon={faGift} className="text-red-800" />;
        default:
          return null;
      }
    };

    
  // Kontrollera om vi ska visa 'before'-bordern eller inte
  const shouldShowBorder = (categoryDescription) => {
    return !['Nyheter', 'Säsong', 'Present'].includes(categoryDescription);
  };

  const stripHyphens = (slug) => {
    return slug.replace(/-/g, '');
  };

  return (
    <nav className="flex flex-1 flex-col dark:text-white" aria-label="Sidebar">
      <h2 className="sidebar-title m-3 font-bold">Kategorier</h2>
      <ul className="space-y-2 font-medium">
  
        {sortedMainCategories.map((category) => (
          <li key={category.id} onClick={() => handleMainCategoryClick(category)} className='border-b dark:border-b-gray-700'>
            <button 
              className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
            >
              {/* Lägg till ikonen innan kategoritexten */}
              <span className="flex items-center">
                {getCategoryIcon(category.description)}
                <span className={`ms-3 ${shouldShowBorder(category.description) ? `before:border-l-4 before:border-${stripHyphens(category?.slug)} before:mr-3` : ''}`}>
                  {category.description}
                </span>
              </span>
              <AiOutlineRight size="10" />
            </button>
            {selectedMainCategory === category && subCategories.length > 0 && (
              <ul className="mt-2 space-y-1 ml-6">
                {subCategories.map((subCat) => (
                  <li key={subCat.id}>
                    <button
        className="text-left w-full px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 rounded"
        onClick={(event) => handleSubCategoryClick(category, subCat, event)}
      >
                      {subCat.description}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Category;
