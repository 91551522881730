// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { initializeFirestore,loadBundle, doc, getDoc, query, where, collection, deleteDoc, batch, addDoc, onSnapshot, persistentLocalCache, persistentMultipleTabManager, getDocsFromCache, namedQuery, getFirestore, getDocs, writeBatch } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage, getDownloadURL, ref, } from "firebase/storage";
import { getPerformance, trace } from "firebase/performance";
import { compress, decompress } from 'lz-string';
import { processProduct } from "../utils/productUtils";
import { openDB } from 'idb';
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = initializeFirestore(app, {
  localCache: persistentLocalCache({
      tabManager: persistentMultipleTabManager(),
      cacheSizeBytes: 10485760  // 10 MB, justera denna siffra beroende på behov
  })
});
// IndexedDB setup
let dbInstance;

export const logTrace = async (traceName, metrics = {}) => {
  try {
    const t = trace(perf, traceName);
    t.start();

    Object.entries(metrics).forEach(([key, value]) => {
      t.incrementMetric(key, value);
    });

    t.stop();

    // Logga till Firestore eller extern tjänst
    const traceLogRef = collection(db, 'performanceLogs');
    await addDoc(traceLogRef, {
      traceName,
      metrics,
      timestamp: new Date().toISOString(),
    });

    console.log(`Trace ${traceName} logged with metrics:`, metrics);
  } catch (error) {
    console.error(`Error logging trace ${traceName}:`, error);
  }
};



export const getIndexedDBInstance = async () => {
  if (!dbInstance) {
    dbInstance = await openDB('product-store', 3, {
      upgrade(indexedDB, oldVersion, newVersion) {
        if (!indexedDB.objectStoreNames.contains('products')) {
          indexedDB.createObjectStore('products', { keyPath: 'id' });
        }
        if (!indexedDB.objectStoreNames.contains('meta')) {
          indexedDB.createObjectStore('meta', { keyPath: 'key' });
        }
        if (!indexedDB.objectStoreNames.contains('stock')) {
          indexedDB.createObjectStore('stock', { keyPath: 'id' });
        }
        if (oldVersion < 2) {
          console.log("IndexedDB schema uppgraderad till version 2");
        }
      },
    });
  }
  return dbInstance;
};

// Funktion för att spara metadata
export const saveMetaToIndexedDB = async (key, value) => {
  const indexedDB = await getIndexedDBInstance(); // Använd instanshämtaren
  await indexedDB.put('meta', { key, value });
};

// Funktion för att hämta metadata
export const getMetaFromIndexedDB = async (key) => {
  const indexedDB = await getIndexedDBInstance(); // Använd instanshämtaren
  const meta = await indexedDB.get('meta', key);
  return meta ? meta.value : null;
};

// Funktion för att spara produkter
export const saveProductsToIndexedDB = async (productsData) => {
  const indexedDB = await getIndexedDBInstance(); // Använd instanshämtaren
  await indexedDB.put('products', { id: 'productsData', data: productsData });
};

// Funktion för att hämta produkter
export const getProductsFromIndexedDB = async () => {
  const indexedDB = await getIndexedDBInstance(); // Använd instanshämtaren
  const productsData = await indexedDB.get('products', 'productsData');
  return productsData ? productsData.data : null;
};

export const listenToProductUpdates = async (callback) => {
  const updatedProductsRef = collection(db, 'updatedProducts');

  const unsubscribe = onSnapshot(updatedProductsRef, async (snapshot) => {
    const updates = [];
    snapshot.forEach((doc) => {
      updates.push({ id: doc.id, ...doc.data() });
    });

    console.log('Hämtade uppdateringar från updatedProducts:', updates);

    // Hämta nuvarande produkter från IndexedDB (bas från JSON)
    const currentProducts = (await getProductsFromIndexedDB()) || [];
    console.log('Nuvarande produkter från IndexedDB:', currentProducts);

    // Om det inte finns några uppdateringar i `updatedProducts`
    if (updates.length === 0) {
      console.log('Inga uppdateringar i updatedProducts, returnerar endast basdata.');
      callback(currentProducts);
      return;
    }

    // Processa uppdaterade produkter
    const processedUpdates = updates.map((product) => processProduct(product));

    // Slå ihop nuvarande produkter med de uppdaterade
    const mergedProducts = currentProducts.map((product) => {
      const update = processedUpdates.find((u) => u.inventoryNumber === product.inventoryNumber);
      return update ? { ...product, ...update } : product;
    });

    // Lägg till produkter som endast finns i `updatedProducts`
    processedUpdates.forEach((update) => {
      if (!mergedProducts.find((product) => product.inventoryNumber === update.inventoryNumber)) {
        mergedProducts.push(update);
      }
    });
    console.log("Processed updates med salesCategories:", processedUpdates);
    console.log('Merged produkter efter processing:', mergedProducts);

    // Spara de sammanslagna produkterna till IndexedDB
    await saveProductsToIndexedDB(mergedProducts);
    console.log('Merged produkter sparade till IndexedDB.');

    // Rensa `updatedProducts`-samlingen i Firestore
    const batch = writeBatch(db);
    updates.forEach((update) => {
      const docRef = doc(updatedProductsRef, update.id);
      batch.delete(docRef);
    });
    await batch.commit();
    console.log('Rensade updatedProducts-samlingen i Firestore.');

    // Skicka callback med de uppdaterade produkterna
    callback(mergedProducts);
  });

  return unsubscribe; // Returnera `unsubscribe`-funktionen
};




export const listenToStockUpdates = async (callback) => {
  const stockRef = collection(db, 'productStock');
  const indexedDB = await getIndexedDBInstance();
  const cachedStock = (await indexedDB.get('stock', 'productStock'))?.data;

  if (cachedStock && cachedStock.length > 0) {
    console.log('Använder cache för stock data.');
    callback(cachedStock);
    return () => {}; // Ingen Firestore-lyssnare behövs om cache används
  }

  let initialLoad = true;
  let readCount = 0;
  const traceName = 'listenToStockUpdates';

  return onSnapshot(stockRef, async (snapshot) => {
    if (initialLoad) {
      const stockData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Spara till IndexedDB
      await indexedDB.put('stock', { id: 'productStock', data: stockData });
      readCount += snapshot.docs.length;
      logTrace(traceName, { phase: 'initial', readOperations: readCount });
      console.log(`Initial load: ${snapshot.docs.length} documents read.`);
      initialLoad = false;

      callback(stockData);
    } else {
      const changes = snapshot.docChanges().length;
      readCount += changes;
      logTrace(traceName, { phase: 'updates', readOperations: changes });
      console.log(`Update: ${changes} document changes detected.`);

      const stockUpdates = {};
      snapshot.docChanges().forEach((change) => {
        if (['added', 'modified'].includes(change.type)) {
          const stockData = { id: change.doc.id, ...change.doc.data() };
          stockUpdates[stockData.id] = stockData;
        }
      });

      callback(stockUpdates);

      // Uppdatera IndexedDB endast med ändringar
      const currentStock = (await indexedDB.get('stock', 'productStock'))?.data || [];
      const mergedStock = currentStock.map((item) =>
        stockUpdates[item.id] ? { ...item, ...stockUpdates[item.id] } : item
      );
      const newEntries = Object.values(stockUpdates).filter(
        (update) => !currentStock.find((item) => item.id === update.id)
      );

      await indexedDB.put('stock', { id: 'productStock', data: [...mergedStock, ...newEntries] });
    }
  });
};






// Funktion för att generera slugs
const generateSlug = (text) => {
  // Ersätt svenska tecken med deras latinska motsvarigheter
  const swedishMap = {
      'å': 'a',
      'ä': 'a',
      'ö': 'o',
      'Å': 'a',
      'Ä': 'a',
      'Ö': 'o'
  };

  // Konvertera text med hjälp av ersättningstabellen
  const normalizedText = text.replace(/[åäöÅÄÖ]/g, (match) => swedishMap[match]);

  // Skapa slug
  return normalizedText
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Byt ut icke-alfanumeriska tecken med bindestreck
      .replace(/^-+|-+$/g, ''); // Ta bort bindestreck i början och slutet
};

export const fetchProductsFromJSON = async () => {
  const response = await fetch('/api/productsData');
  if (!response.ok) {
    throw new Error('Failed to fetch product JSON file');
  }
  const productsData = await response.json();
  return productsData;
};


export const loadCategoriesAndSubCategories = async () => {
  const traceName = 'loadCategoriesAndSubCategories';
  let readCount = 0; // För att spåra läsningar

  const t = trace(perf, traceName);
  t.start();
  try {
      const firestore = getFirestore();
      // console.log('loadCategoriesAndSubCategories triggered');

      // Hämta alla kategorier
      const categoriesSnapshot = await getDocs(collection(firestore, 'categories'));
      // console.log('Laddade kategorier:', categoriesSnapshot.docs);

      // Hämta alla underkategorier och skapa slugs
      const categories = await Promise.all(
          categoriesSnapshot.docs.map(async (doc) => {
              const categoryData = doc.data();
              const categoryId = doc.id;

              const subcategoriesRef = collection(firestore, `categories/${categoryId}/subCategories`);
              const subcategoriesSnapshot = await getDocs(subcategoriesRef);

              const subCategories = subcategoriesSnapshot.docs.map((subDoc) => ({
                  ...subDoc.data(),
                  id: subDoc.id,
                  slug: generateSlug(subDoc.data().description || subDoc.id), // Skapa slug för underkategori
              }));
              readCount += subcategoriesSnapshot.docs.length; // Läsning för underkategorier
              return {
                  ...categoryData,
                  id: categoryId,
                  slug: generateSlug(categoryData.description || categoryId), // Skapa slug för kategori
                  subCategories,
              };
          })
      );

    //   console.log('Loaded categories and subcategories with slugs:', categories);
    t.incrementMetric('readOperations', readCount); // Logga totalt antal läsningar
    t.stop();
      return categories;
  } catch (error) {
      console.error("Error loading categories and subcategories:", error);
      throw error;
  }
};








export const loadDiscountBundleAndQuery = async () => {
  try {
    const metadataRef = doc(getFirestore(), 'metadata', 'lastDiscountUpdate');
    const metadataDoc = await getDoc(metadataRef);
    const lastUpdate = metadataDoc.exists() ? metadataDoc.data().lastModifiedDateTime : null;

    const cachedBundleTimestamp = localStorage.getItem("lastDiscountBundleLoadTimestamp");
    let shouldReloadBundle = !cachedBundleTimestamp || new Date(cachedBundleTimestamp) < new Date(lastUpdate);

    if (shouldReloadBundle) {
      const bundleRef = ref(storage, "bundles/discountsBundle.bundle");
      const url = await getDownloadURL(bundleRef);
      const response = await fetch(url);
      const bundleBuffer = await response.arrayBuffer();
      await loadBundle(getFirestore(), bundleBuffer);
      localStorage.setItem("lastDiscountBundleLoadTimestamp", lastUpdate);
    }

    const query = await namedQuery(getFirestore(), "discounts-query");
    if (query) {
      const snapshot = await getDocsFromCache(query);
      return snapshot.docs.map(doc => doc.data());
    }
    return [];
  } catch (error) {
    console.error("Error loading discounts bundle:", error);
    throw error;
  }
};

// Initialize Performance Monitoring and get a reference to the service
export const perf = getPerformance(app);

export const authentication = getAuth(app);
export const storage = getStorage(app);
