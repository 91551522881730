import React, { createContext, useContext, useState, useEffect } from 'react';
import { authentication, db } from '../firebase/firebase';
import { collection, doc, getDoc, getDocs, query, where, or } from 'firebase/firestore';
import { encryptData, decryptData } from '../services/cryptoUtils';

const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [userData, setUserData] = useState(null);
    const [discountInfo, setDiscountInfo] = useState(null);
    const [role, setRole] = useState(null);
    const [supplierId, setSupplierId] = useState(null);

    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

    useEffect(() => {
        const fetchCustomerData = async (authUID) => {
          console.log('Fetching customer data for authUID:', authUID);
      
          try {
            const customersRef = collection(db, 'customers');
      
            // 1. Kontrollera om authUID är kopplat till ett huvudkonto
            const primaryQuery = query(customersRef, where('authUID', '==', authUID));
            const primarySnapshot = await getDocs(primaryQuery);
      
            if (!primarySnapshot.empty) {
              console.log('Primary account found for authUID:', authUID);
              const customerDoc = primarySnapshot.docs[0];
              return { id: customerDoc.id, ...customerDoc.data() };
            }
      
            // 2. Kontrollera om authUID finns i accounts manuellt
            console.log('Primary account not found, checking secondary accounts for authUID:', authUID);
      
            const allCustomersSnapshot = await getDocs(customersRef);
      
            for (const doc of allCustomersSnapshot.docs) {
              const customerData = doc.data();
      
              // Kontrollera om accounts innehåller authUID
              if (customerData.accounts) {
                const accountMatch = customerData.accounts.find(account => account.authUID === authUID);
                if (accountMatch) {
                  console.log('Secondary account found in customer document:', doc.id);
                  return { id: doc.id, ...customerData };
                }
              }
            }
      
            // 3. Om ingen matchning hittas
            console.error('No customer data found for authUID:', authUID);
            throw new Error('Kunddata kunde inte hittas för detta konto.');
          } catch (error) {
            console.error('Error in fetchCustomerData:', error);
            return null;
          }
        };
      
        const unsubscribe = authentication.onAuthStateChanged(async (userAuth) => {
          if (userAuth) {
            console.log('User logged in with authUID:', userAuth.uid);
            setUser(userAuth);
      
            try {
              // Hämta kunddata (primärt eller sekundärt konto)
              const customerData = await fetchCustomerData(userAuth.uid);
      
              if (customerData) {
                console.log('Customer data found:', customerData);
      
                const customerNumber = customerData.id;
      
                // Hämta användardata och rabatter
                const discountPercent = await checkDiscounts(customerNumber);
                const updatedData = { ...customerData, discount: discountPercent };
      
                // Kryptera användaruppgifter och spara i sessionStorage
                sessionStorage.setItem('userData', encryptData(updatedData));
      
                // Uppdatera UserContext state
                setUserData(updatedData);
                setRole(updatedData.role);
                setSupplierId(updatedData.supplierId);
              } else {
                console.error('Failed to fetch customer data for authUID:', userAuth.uid);
                setUserData(null);
                setRole(null);
                setSupplierId(null);
              }
            } catch (error) {
              console.error('Error fetching customer data:', error);
              setUserData(null);
              setRole(null);
              setSupplierId(null);
            }
          } else {
            console.log('No user logged in.');
            // Nollställ data och ta bort från sessionStorage vid utloggning
            setUser(null);
            setUserData(null);
            setDiscountInfo(null);
            setRole(null);
            setSupplierId(null);
            sessionStorage.removeItem('userData');
          }
        });
      
        // Dekryptera och ladda in användaruppgifter från sessionStorage när komponenten monteras
        const storedUserData = sessionStorage.getItem('userData');
        if (storedUserData) {
          const decryptedData = decryptData(storedUserData);
          setUserData(decryptedData);
          setRole(decryptedData.role);
          setSupplierId(decryptedData.supplierId);
        }
      
        return unsubscribe;
      }, []);
      
      

    const checkDiscounts = async (userNumber) => {
        const discountsRef = collection(db, 'discounts');
        const discountsSnapshot = await getDocs(discountsRef);
        let discountPercent = 0;

        discountsSnapshot.forEach(doc => {
            const data = doc.data();
            if (data.customers) {
                const customerIds = data.customers.map(customer => customer.customer);

                if (customerIds.includes(userNumber)) {
                    discountPercent = getDiscountPercentFromCode(data.discountCode);
                    setDiscountInfo({ discountId: doc.id, description: data.description, discountPercent });
                    return;
                }
            }
        });
        return discountPercent;
    };

    const getDiscountPercentFromCode = (discountCode) => {
        switch (discountCode) {
            case "03":
                return 3;
            case "05":
                return 5;
            case "06":
                return 6;
            case "07":
                return 7;
            case "08":
                return 8;
            case "10":
                return 10;
            case "11":
                return 11;
            case "12":
                return 12;
            case "15":
                return 15;
            case "30":
                return 30;
            case "CUSTOM15":
                return 15;
            default:
                return 0;
        }
    };

    return (
        <UserContext.Provider value={{ userData, setUserData, user, discountInfo, role, supplierId }}>
            {children}
        </UserContext.Provider>
    );
};
