import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";
import { useProductContext } from "../context/ProductContext";
import { CartContext } from "../context/cart";
import ProductBreadcrumb from "./ProductBreadcrumb";
import {Tab } from '@headlessui/react';
import { useUserContext } from "../context/userContext";
import SimilarProducts from "./SimilarProducts"; 
import { Helmet } from "react-helmet";
import { ref,uploadBytes, getDownloadURL, deleteObject, uploadBytesResumable  } from "firebase/storage";
import { storage } from "../firebase/firebase";
import ProductPDF from "../utils/pdfCreator";
import { PDFDownloadLink } from '@react-pdf/renderer';
import DiscountNotification from "../components/DiscountNotification";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ProductDescription from "./ProductDescription";
import { useAuth } from "../context/authContext";

const ProductDetail = () => {
  const { cartItems, addToCart, removeFromCart } = useContext(CartContext);
  const { mainCategorySlug, inventoryNumber } = useParams(); 
  const { isAdmin} = useAuth();
  const [promoProduct, setPromoProduct] = useState(null); // För att lagra promo-datan
  const { discountedProducts, klippvaror, products, promotionalContent,stockLevels = {} } = useProductContext(); 
  const { user, role } = useUserContext();
  const [poOrder, setPoOrder] = useState([]);
  const [product, setProduct] = useState(null);
  const [klippvara, setKlippvara] = useState(null);
  const [stockStatus, setStockStatus] = useState("");
  const [isCheckingAvailability, setIsCheckingAvailability] = useState(false);
  const [animation, setAnimation] = useState(false);
  const location = useLocation(); // Hämta URL
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [ingredients, setIngredients] = useState("");
  const [nutritionalValues, setNutritionalValues] = useState([]);
  
  const productFolderPath = `productImages/${inventoryNumber}/`;

// Stock values 
const stockInfo = stockLevels[product?.inventoryNumber] || {};

const handleFileChange = (e) => {
  setSelectedFiles(Array.from(e.target.files)); // Konvertera FileList till Array
};

const handleUploadImages = () => {
  if (!selectedFiles || selectedFiles.length === 0) {
    console.error("Inga filer valda för uppladdning");
    return;
  }

  selectedFiles.forEach((file) => {
    const fileRef = ref(storage, `productImages/${inventoryNumber}/${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    //    console.log(`Upload is ${progress}% done`);
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Error uploading file:', error);
      },
      () => {
        
      //  console.log('File uploaded successfully');
      }
    );
  });
};

const handleDeleteImage = async (imageUrl) => {
  if (!isAdmin) {
    alert("Du har inte behörighet att ta bort bilder.");
    return;
  }


  const imageRef = ref(storage, imageUrl);

  try {
    await deleteObject(imageRef);
    alert("Bilden har tagits bort.");
  } catch (error) {
    console.error("Error deleting file:", error);
    alert("Ett fel uppstod när bilden skulle tas bort.");
  }
};

  useEffect(() => {
    // Kontrollera att alla beroenden är tillgängliga innan vi fortsätter
    if (!discountedProducts || !products || !klippvaror || !promotionalContent || !inventoryNumber) {
      return; // Avsluta om inte alla beroenden är tillgängliga
    }
  
    // Hitta produkten i klippvaror först
    const foundKlippvara = klippvaror.find((klipp) => klipp.inventoryNumber === inventoryNumber);
    // Annars, gå vidare till att välja den vanliga produktdatan
    const availableProducts = discountedProducts.length > 0 ? discountedProducts : products;

    // Hitta den specifika produkten baserat på inventoryNumber
    const foundProduct = availableProducts.find((product) => product.inventoryNumber === inventoryNumber);

    if (foundKlippvara) {
      setKlippvara(foundKlippvara);
      setProduct(foundProduct); // Nollställ ordinarie produkt om klippvaran hittades
    } else {
  
      if (foundProduct) {
        setProduct(foundProduct);
        setKlippvara(null); // Nollställ klippvaran om ordinarie produkten hittades
      }
    }
}, [discountedProducts, products, klippvaror, promotionalContent, inventoryNumber]);
// Bearbeta body och note för att extrahera information
// Bearbeta body och note för att extrahera information
useEffect(() => {
  if (!product?.body && !product?.note) return;

  const processBody = () => {
    if (typeof product.body === 'string') {
      // Rensa radbrytningar och trimma överflödiga mellanslag
      const strippedBody = product.body.replace(/\/\* RTE style begin \*\/.*?\/\* RTE style end \*\//s, '');

      // Rensa bort alla HTML-taggar från kvarvarande text
      const cleanedBody = strippedBody
        .replace(/<\/?[^>]+(>|$)/g, '') // Ta bort HTML-taggar
        .replace(/&nbsp;/g, ' ') // Ersätt &nbsp; med ett vanligt mellanslag
        .replace(/(\r\n|\n|\r)/gm, ' ') // Ta bort radbrytningar
        .trim(); // Trimma överflödiga mellanslag

      // Dela upp texten i sektioner baserat på nyckelorden
      const sections = cleanedBody.split(/Ingredienser:|Tillsatsämnen:|Allergener:|Näringsvärde per/);

      // Kort beskrivning (allt innan "Ingredienser:")
      const shortDescription = sections[0]?.trim() || '';

      // Extrahera ingredienser
      let ingredients = '';
      if (cleanedBody.includes('Ingredienser:')) {
        ingredients = sections[1]?.split('Tillsatsämnen:')[0]?.trim() || '';
      }
      setIngredients(ingredients);

      // Hantera näringsvärden
      if (cleanedBody.includes('Näringsvärde per')) {
        const nutritionalContent = sections[4]?.trim() || '';

        // Rensa bort eventuell text som ". 100 g" och dela upp korrekt
        const cleanedNutritionalContent = nutritionalContent.replace(/^\.?\s*100 g:/, '').trim();

        // Lista över förväntade näringsvärden för korrekt uppdelning
        const nutritionalLabels = [
          "Energi",
          "Fett",
          "Mättade fettsyror",
          "Kolhydrat",
          "Sockerarter",
          "Kostfiber",
          "Protein",
          "Salt",
        ];

        // Dela upp näringsvärden baserat på kommatecken
        const nutritionalRows = cleanedNutritionalContent.split(',').map(row => row.trim());

        const nutritionalValues = [];

        // Hantera energivärden (kJ och kcal) separat
        const energyValue = nutritionalRows.find(row => row.startsWith("Energi"));
        if (energyValue) {
          const energySplit = energyValue.replace('Energi ', '').trim(); // Ta bort etiketten och behåll värdet
          nutritionalValues.push({ label: "Energi", value: energySplit });
        }

        // Hantera de andra näringsvärdena och lägg till "g" där nödvändigt
        nutritionalValues.push(
          ...nutritionalLabels.slice(1).reduce((acc, label) => {
            const value = nutritionalRows.find(row => row.startsWith(label));
            if (value) {
              let valueSplit = value.replace(`${label} `, '').trim(); // Ta bort etiketten

              // Hoppa över rader utan värde
              if (!valueSplit || valueSplit.toLowerCase() === 'g') return acc;

              // Om det är ett numeriskt värde, säkerställ att det slutar med "g"
              if (/^-?\d+([,\.]\d+)?$/.test(valueSplit)) {
                valueSplit += " g"; // Lägg till "g" om det saknas
              }

              acc.push({ label, value: valueSplit });
            }
            return acc;
          }, [])
        );

        setNutritionalValues(nutritionalValues);
      }
    }
  };

  processBody();
}, [product]);



  
//  console.log('produktdata:', product);

 // Skydda mot att product kan vara null
 const salesCategory = product?.salesCategories && product.salesCategories.length > 0 ? product.salesCategories[0] : null;

 // Kontrollera om FRP-attributet finns i produkten eller i klippvarorData
 const frpAttribute = product?.attributes?.find(attribute => attribute.id === "FRP")
 const viktAttribute = product?.attributes?.find(attribute => attribute.id === "VIKT");

 const isDiscounted = product?.discountedPrice !== null && product?.discountedPrice !== undefined && !isNaN(product?.discountedPrice);
 const isKlippvara = klippvara !== null;
 const itemPrice = promoProduct
 ? promoProduct.discountType === 'percentage'
   ? product.defaultPrice * (1 - promoProduct.discountValue / 100)
   : product.defaultPrice - promoProduct.discountValue
 : !isNaN(product?.discountedPrice) && product?.discountedPrice > 0
   ? product.discountedPrice
   : product?.defaultPrice ?? 0;

// console.log('discountedPrice', product?.discountedPrice);
   //  console.log('item price', itemPrice);
     const klippvarorPrice = isKlippvara ? klippvara.discountedPrice : null;
 // Formatera priset
 const formattedPrice = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(itemPrice);
 // Dynamiskt tilldela klass baserat på om priset är rabatterat och discountedPrice är giltigt
const priceClass = (isDiscounted && product.discountedPrice !== null && product.discountedPrice !== undefined && !isNaN(product.discountedPrice)) || promoProduct
? 'text-red-700 dark:text-red-500'
: 'text-gray-900 dark:text-white';

 

const calculateAvailableStock = (stockInfo) => {
  if (!stockInfo || !stockInfo.aggregatedSummary) {
    return stockInfo?.quantityOnHand || 0; // Om `stockInfo` eller `aggregatedSummary` saknas, returnera `quantityOnHand` eller 0.
  }

  const { notAvailable, soAllocated, soBackOrdered, soBooked, soShipped } = stockInfo.aggregatedSummary;

  // Summera alla värden från aggregatedSummary
  const totalReserved = (notAvailable || 0) + (soAllocated || 0) + (soBackOrdered || 0) + (soBooked || 0) + (soShipped || 0);

  // Beräkna det tillgängliga lagersaldot
  return Math.max(stockInfo.quantityOnHand - totalReserved); // Säkerställ att värdet inte blir negativt
};


 const bestVara = product?.attributes?.find(attribute => attribute.id === "BEST" && attribute.value === "1");
 const frpValue = frpAttribute ? frpAttribute.value : null;
 const viktValue = viktAttribute ? viktAttribute.value : null;
 
 const incrementValue = frpValue ? parseInt(frpValue) : 1;

 const available = calculateAvailableStock(stockInfo); // Skydda mot att supplierDetails kan vara null eller undefined
 let supplierName = '';
 let supplierId = '';

 if (product?.supplierDetails && product.supplierDetails.length > 0) {
   supplierName = product.supplierDetails[0].supplierName;
   supplierId = product.supplierDetails[0].supplierId;
 }// Funktion för att beräkna lagerstatus


 console.log('stock Info igen', stockInfo);
const getStockStatus = (available, incrementValue) => {
  if (available <= 0) {
    return "Slut i lager";
  } else if (available <= incrementValue * 3) {
    return `< ${incrementValue * 3} i lager`;
  } else {
    return "I lager";
  }
};
// Sätt lagerstatus baserat på tillgänglighet
useEffect(() => {
  if (product && available !== undefined) {
    const status = getStockStatus(available, incrementValue);
    setStockStatus(status);
  }
}, [product, available, incrementValue]);

// Funktion för att kolla tillgänglighet via API
const checkAvailability = async () => {
  setIsCheckingAvailability(true); // Visa att vi kollar tillgänglighet
  try {
    const response = await axios.get(`/api/check-availability`, {
      params: { supplierId: supplierId,
        inventoryNumber: inventoryNumber
       }
    });
    if (response.data.promisedOn) {
      setPoOrder(response.data.promisedOn); // Spara promisedOn-värdet
    } else if (response.data.message) {
      setPoOrder(response.data.message); // Visa meddelandet om ingen planerad inleverans hittas
    }
  
  } catch (error) {
    console.error('Error fetching order history:', error);
  } finally {
    setIsCheckingAvailability(false); // Ta bort laddningstillstånd
  }
};



if (!product || !product.defaultPrice) {
  return <p>Laddar produktdata...</p>;
}




const handleAddToCart = (product, klippvara = null) => {
  const prefixedInventoryNumber = getPrefixedInventoryNumber(product, klippvara);


     // Bestäm rätt pris
  let discountedPrice = klippvara?.discountedPrice !== undefined && !isNaN(klippvara?.discountedPrice)
  ? klippvara.discountedPrice
  : null;

// Om inget giltigt klippvarupris finns, kolla om det finns en kampanj
if (discountedPrice === null && promoProduct) {
  discountedPrice = promoProduct.discountType === 'percentage'
    ? product.defaultPrice * (1 - promoProduct.discountValue / 100)
    : product.defaultPrice - promoProduct.discountValue;
}

// Använd produktens ordinarie rabatt eller defaultPrice om inget annat gäller
if (discountedPrice === null) {
  discountedPrice = (!isNaN(product?.discountedPrice) && product?.discountedPrice !== null)
    ? product.discountedPrice
    : product.defaultPrice;
}

  // Lägg till kontroll: Om discountedPrice fortfarande är NaN, använd defaultPrice
  if (isNaN(discountedPrice)) {
    discountedPrice = product.defaultPrice;
  }


const isKlippvara = Boolean(klippvara);
const productToAdd = isKlippvara
  ? {
      ...klippvara,
      inventoryNumber: `${product.inventoryNumber}-KLIPP`, // Lägg till suffix för klippvaror
      discountedPrice: klippvara.discountedPrice,
      price: klippvara.discountedPrice,
    }
  : {
      ...product,
      inventoryNumber: product.inventoryNumber,
      discountedPrice: promoProduct
        ? promoProduct.discountType === 'percentage'
          ? product.defaultPrice * (1 - promoProduct.discountValue / 100)
          : product.defaultPrice - promoProduct.discountValue
        : product.discountedPrice ?? product.defaultPrice,
      price: product.defaultPrice,
    };

  // console.log('productToAdd:', productToAdd)
  // Lägg till i varukorgen med korrekt pris
  addToCart(
    productToAdd,
    klippvara?.lotSerialNumber,
    klippvara?.expirationDate,
    1, // Default quantity
    productToAdd.discountedPrice // Skicka med discountedPrice
  );

  // Sätt animation till true när vi tar bort produkten från varukorgen
  setAnimation(true);
  // Ta bort animationen efter 0.5 sekunder (matchar CSS-animationens längd)
  setTimeout(() => {
    setAnimation(false);
  }, 200);
};

const handleRemoveFromCart = (product, klippvara) => {
  const prefixedInventoryNumber = getPrefixedInventoryNumber(product, klippvara);
  removeFromCart({ ...product, inventoryNumber: prefixedInventoryNumber });

  setAnimation(true);
  // Ta bort animationen efter 0.5 sekunder (matchar CSS-animationens längd)
  setTimeout(() => {
    setAnimation(false);
  }, 200);
};

const getPrefixedInventoryNumber = (product, klippvara) => {
  if (klippvara) {
    return `${product.inventoryNumber}-KLIPP`;
  }
  return product.inventoryNumber;
};

const placeholderImageUrl = 'https://via.placeholder.com/250';

// Hämta första bilden som inte är en "web"-bild
const firstImageSrc = product.productImages && product.productImages.length > 0 
    ? product.productImages.find(image => !image.includes('/web/')) || placeholderImageUrl
    : placeholderImageUrl;

// Mappa ut alla bilder och videor (exkludera "/web/")
const mediaFiles = [
  ...(product.productImages?.filter(image => !image.includes('/web/')) || []),
  ...(product.productVideos || [])
];
// Kontrollera om värden saknas
if (!product.body && !product.note) {
  console.log("Produktinformation saknas");
  return;
}






  return (
    <div className="bg-white dark:bg-gray-800 dark:text-white  pt-10 md:pt-20">
    

    {/* Resten av komponentens JSX */}
      <DiscountNotification />  
      <div className="max-w-full px-4 sm:px-6 sm:py-8 lg:max-w-full lg:px-8 ">
        <ProductBreadcrumb product={product} mainCategorySlug={mainCategorySlug} />
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 mb-10">
        <Tab.Group as="div" className="flex flex-col-reverse">


        {isAdmin && (
        <div className="image-upload-section">
          <h3>Ladda upp produktbilder</h3>
          <input type="file" multiple onChange={handleFileChange} />
          <button onClick={handleUploadImages}>Ladda upp bilder</button>
          <p>Uppladdningsprogress: {uploadProgress.toFixed(2)}%</p>
<div className="flex">
          {product.productImages && product.productImages.map((image, index) => (
            <div key={index} className="uploaded-image max-w-32 max-h-32">
              <img src={image} alt={`Produktbild ${index + 1}`} />
              <button onClick={() => handleDeleteImage(image)}>Ta bort</button>
            </div>
          ))}
          </div>
        </div>
      )}

  <div className="mx-auto mt-6 w-full max-w-2xl sm:block lg:max-w-none">
    <Tab.List className="grid grid-cols-4 gap-6">
      {mediaFiles.length > 0 ? (
        mediaFiles.map((file, index) => (
          <Tab
            key={index}
            className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
          >
        {file.endsWith('.mp4') || file.endsWith('.webm') ? (
  <div className="relative w-full h-full">
    <video
      src={file}
      alt={`Video ${index}`}
      className="card-img h-full w-full object-cover object-center"
      muted
    />
    {/* Play-ikon overlay */}
    <div className="absolute inset-0 flex items-center justify-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-12 h-12 text-white opacity-70"
      >
        <path d="M8 5v14l11-7z" />
      </svg>
    </div>
  </div>
) : (
              <img
                src={file}
                alt={`Image ${index}`}
                className="card-img h-full w-full object-contain object-center"
              />
            )}
          </Tab>
        ))
      ) : (
        <Tab className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4">
          <img
            src={placeholderImageUrl}
            alt="Placeholder Image"
            className="card-img h-full w-full object-cover object-center"
          />
        </Tab>
      )}
    </Tab.List>
  </div>

  <Tab.Panels className="aspect-h-1 aspect-w-1 w-full h-auto">
    {mediaFiles.length > 0 ? (
      mediaFiles.map((file, index) => (
        <Tab.Panel
          className="max-h-screen h-auto object-center justify-center flex"
          key={index}
        >
          {file.endsWith('.mp4') || file.endsWith('.webm') ? (
            <video
              src={file}
              controls
              className=" w-auto object-contain object-center sm:rounded-l"
            />
          ) : (
            <Zoom><img
              src={file}
              alt={`Image ${index}`}
              className="h-full max-h-96 w-auto object-contain object-center sm:rounded-l"
            /></Zoom>
          )}
        </Tab.Panel>
      ))
    ) : (
      <Tab.Panel>
        <img
          src={placeholderImageUrl}
          alt="Placeholder Image"
          className="h-full w-full object-cover object-center sm:rounded-l"
        />
      </Tab.Panel>
    )}
  </Tab.Panels>



</Tab.Group>


          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            {bestVara && user && !product.status === "NoSales" && ( <span className="  inline-flex items-center rounded-md bg-orange-600 border px-2 py-1 text-xs font-medium text-orange-900 dark:text-white">
                     Beställningsvara
                  </span>)}
                  {promoProduct && (
          <p className="text-red-600">
            Kampanjpris! {promoProduct.discountType === 'percentage'
              ? `${promoProduct.discountValue}% rabatt`
              : `-${promoProduct.discountValue} kr`}
          </p>
        )}
              {product.discountType === 'buyXPayForY' && (
          <p className="text-orange-800 bg-orange-100 inline-block p-2 font-semibold rounded-sm my-2">
            Kampanjvara!
          </p>
        )}
        
        <p>{supplierName}</p>
              <h1 className="xl:text-3xl lg:text-2xl md:text-xl font-bold tracking-tight text-gray-900 dark:text-white">{product.description}</h1>
              <div className="mt-3">
                <p>{product.inventoryNumber}- {viktValue} - {frpValue}</p>
                <h3 className="sr-only">Beskrivning:</h3>
               
                {product.note && (
                  <div className="space-y-6 mt-8  text-base text-gray-700 dark:text-white">
                    <span className="short-description space-y-6  text-lg text-gray-700 dark:text-white"><ProductDescription description={product.note} /></span>
                   </div>)}
                   {user  && product.status !== 'NoSales' && (
  <div className="mt-6">
    <p className={`text-3xl font-semibold tracking-tight ${priceClass}`}>
          Pris: {formattedPrice}
        </p> {bestVara && ( <span className=" inline-flex items-center rounded-md  my-6 border border-orange-100 p-2 text-xs font-medium text-amber-800 dark:bg-orange-200">
                    
      Vid beställning av beställningsvaror skapas en separat order då dessa varor inte finns i lager vid tillfället för din beställning. 
                      Vi återkommer när dessa varor beräknas vara i lager. OBS! Beställningsvaror räknas inte mot fraktfrinivån då de hamnar på separat order.
                  </span>)}
                  
    <div className="stock text-md font-semibold text-gray-600 pt-2 text-gray-600 dark:text-gray-300">
    {!bestVara && (  <> Lagerstatus: {stockStatus}</>

    )}

      {/* Visa knappen endast om stockStatus är 'Slut i lager' */}
      {stockStatus === 'Slut i lager' && (
        <div className="mt-4">
          <button
            className="px-4 py-2 bg-slate-600 text-white text-xs font-bold uppercase rounded hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
            onClick={checkAvailability}
            disabled={isCheckingAvailability}
          >
            {isCheckingAvailability ? "Kontrollerar..." : "Kontrollera inleveransdatum"}
          </button>
          {poOrder.length !== 0 ? (<><p className="mt-2 text-sm text-gray-600 dark:text-gray-300">Uppskattat inleveransdatum: * </p>
           <p className="text-semibold text-gray-600 dark:text-gray-300"> {poOrder}</p>
            <p className="text-xs text-gray-600 dark:text-gray-300"> * observera att dessa datum kan komma att förändras med tiden</p></>) : ( null )}
        </div>
      )}
    </div>

    {/* Varukorgshantering */}
    <div className='mt-6 flex justify-between items-center'>
      {!cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, null)) ? (
        <button className='px-4 py-2 bg-gray-800 dark:bg-gray-600  text-white dark:text-white text-xs transform active:scale-75 transition-transform font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700'
          onClick={() => handleAddToCart(product, null)}>
          Lägg i varukorg
        </button>
      ) : (
        <div className="flex gap-4 items-center dark:bg-gray-700 justify-between w-full mx-auto md:mx-0 md:w-max">
          <button
            className="px-4 py-2 bg-gradient-to-r from-red-400 w-1/3 h-14 max-w-16 md:w-auto md:h-auto  to-red-500 text-white text-xs font-bold uppercase transform active:scale-75 transition-transform  rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
            onClick={() => handleRemoveFromCart(product, null)}>
            -
          </button>
          <p className={`text-gray-600 text-xl md:text-sm text-center   dark:text-white ${animation ? 'animate-pulse-custom' : ''}`}>{cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, null)).quantity}</p>
          <button
            className='px-4 py-2 bg-gradient-to-r from-green-400 to-green-500 w-1/3 h-14 max-w-16 md:w-auto md:h-auto  text-white text-xs font-bold transform active:scale-75 transition-transform   uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700'
            onClick={() => {
              handleAddToCart(product, null);  // Kallar första funktionen
              setAnimation(true);              // Kallar andra funktionen
            }}
            onAnimationEnd={() => setAnimation(false)}>
            +
          </button>
        </div>
      )}
    </div>
  </div>
)}
            {klippvara && user && (
              <div className="mt-8 border-t border-gray-200 dark:border-gray-600 pt-8">
                <h2 className="text-lg text-red-700 font-bold dark:text-white">Denna vara finns även som klippvara</h2>
                <p className="text-md font-semibold">Bäst före datum: {klippvara.expirationDate}</p>
                <p className="text-lg font-bold">Pris: {new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(klippvara.discountedPrice)}</p>
                {!cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, klippvara)) ? (
                  <button
                    className='px-4 py-2 bg-gray-800 dark:bg-gray-600 text-white dark:text-white text-xs font-bold uppercase rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700'
                    onClick={() => handleAddToCart(product, klippvara)}>
                    Lägg i varukorg
                  </button>
                ) : (
                  <div className="flex gap-4">
                    <button
                      className="px-4 py-2 bg-red-400/75 text-white text-xs font-bold uppercase rounded hover:bg-red-700 focus:outline-none focus:bg-red-700"
                      onClick={() => handleRemoveFromCart(product, klippvara)}>
                      -
                    </button>
                    <p className='text-gray-600 dark:text-white'>
                      {cartItems.find(item => item.inventoryNumber === getPrefixedInventoryNumber(product, klippvara)).quantity}
                    </p>
                    <button
                      className="px-4 py-2 bg-green-400/75 text-white text-xs font-bold uppercase rounded hover:bg-green-700 focus:outline-none focus:bg-green-700"
                      onClick={() => handleAddToCart(product, klippvara)}>
                      +
                    </button>
                  </div>
                )}
              </div>
            )}
          
      
               <div className="mt-3 text-sm font-medium text-gray-900 dark:text-white">Ingår i kategori: <Link className="block prose prose-sm  text-gray-500 dark:text-white" to={`/cat/${salesCategory.slug}`}>
                {salesCategory.description}
              </Link></div>

           {/* Ingredienser */}
          {/* Ingredienser */}
{ingredients && (
  <div className="ingredients mt-6">
    <h2 className="text-sm font-medium text-gray-900 dark:text-white">Ingredienser:</h2>
    <p className="prose prose-sm mt-4 text-gray-500 dark:text-gray-400">{ingredients}</p>
  </div>
)}

{/* Näringsvärde */}
{nutritionalValues.length > 0 && (
  <div className="nutritional-value mt-6">
    <h2 className="text-sm font-medium text-gray-900 dark:text-white">Näringsvärde per 100 g:</h2>
    <div className="prose prose-sm mt-4 text-gray-500 dark:text-white">
      {nutritionalValues.map((item, index) => (
        <div key={index} className="flex justify-between border-b p-2">
          <span className="font-medium">{item.label}</span>
          <span className="text-right">{item.value}</span>
        </div>
      ))}
    </div>
  </div>
)}



              </div>
             
             
         
           
            <section aria-labelledby="policies-heading" className="mt-10">
              <dl className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                <div className="rounded-lg border border-gray-200 bg-gray-50 dark:bg-gray-600 p-6 text-center" >
                  <dt>
                  
                  <PDFDownloadLink className="" document={<ProductPDF product={product} productImageUrl={product?.productImages[0]} />} fileName={`${product.description}.pdf`}>
        {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Ladda ned Produktblad')}
      </PDFDownloadLink>
                 </dt>
                  <dd className="mt-1 text-sm text-gray-500"></dd>
                </div>
              </dl>
            </section>
          </div>
        </div>
        {stockStatus === 'I lager' ? (
          <>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Fler produkter från {supplierName}</h1>
            <SimilarProducts currentProduct={product} filterBySupplier />
          </>
        ) : (
          <>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Produkter som liknar {product.description}</h1>
            <SimilarProducts currentProduct={product} />
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDetail;