import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firebase';
import { setDoc, doc, getDocs, getDoc, collection, deleteDoc } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid'; // För att skapa unika ID:n för nya promotions
import { searchProducts } from '../services/searchUtils'; // Importera din search-funktion
import { useProductContext } from '../context/ProductContext';
const PromotionalEditForm = ({ role }) => {
  const [promotions, setPromotions] = useState([]);
  const [title, setTitle] = useState(''); // Nytt fält för titel
  const [bannerImage, setBannerImage] = useState('');
  const [description, setDescription] = useState('');
  const [buttonText, setButtonText] = useState('');

  const [categorySlug, setCategorySlug] = useState('');
  const [supplierSlug, setSupplierSlug] = useState('');
  const [searchQuery, setSearchQuery] = useState(''); // Håll sökqueryn
  const [searchResults, setSearchResults] = useState([]); // Håll sökresultaten
  const [productsInCampaign, setProductsInCampaign] = useState([]); // Lista av produkter tillagda i kampanjen
  const [selectedPromotion, setSelectedPromotion] = useState(null); // För att veta vilken promotion som är vald för uppdatering
  const [selectedProduct, setSelectedProduct] = useState(null); // För vald produkt
  const [productArticleNumber, setProductArticleNumber] = useState(''); // Artikelnummer för att söka produkt
  const { products } = useProductContext(); // Hämta produkter från kontext
  const [discountType, setDiscountType] = useState('percentage');
  const [discountValue, setDiscountValue] = useState(0);
  const [slug, setSlug] = useState(''); // Ny slug genereras automatiskt

  // Hämta promotions från Firestore
  
  useEffect(() => {
    fetchPromotions();
  }, []);


  const fetchPromotions = async () => {
    try {
      const promotionsRef = collection(db, 'promotionalContent');
      const promotionSnapshot = await getDocs(promotionsRef);
      const promotionData = promotionSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPromotions(promotionData);
    } catch (error) {
      console.error('Error fetching promotions:', error);
    }
  };


// Funktion för att generera en slug från en sträng
const generateSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^\w ]+/g, '') // Ta bort specialtecken
    .replace(/ +/g, '-'); // Byt ut mellanslag mot bindestreck
};


  // Uppdatera slugen automatiskt när titeln ändras
  useEffect(() => {
    setSlug(generateSlug(title)); // Generera slug baserat på titeln
  }, [title]);

  // Uppdatera sökresultaten varje gång searchQuery ändras
  useEffect(() => {
    if (searchQuery) {
      const results = searchProducts(products, searchQuery); // Använd din söklogik
      setSearchResults(results.slice(0, 10)); // Begränsa till 10 resultat
    } else {
      setSearchResults([]);
    }
  }, [searchQuery, products]);


  const addProductToCampaign = (product) => {
    const promoProduct = {
      ...product,
      discountType: 'percentage', // Standard till procentuell rabatt
      discountValue: 0,
      originalPrice: product.defaultPrice, // Lagra originalpris för beräkning
      discountedPrice: null, // Standard till null tills rabatten appliceras
    };
    setProductsInCampaign([...productsInCampaign, promoProduct]);
    setSearchQuery(''); // Återställ sökfältet
  };

  const handleSubmit = async (e) => {
  e.preventDefault();
  const id = selectedPromotion ? selectedPromotion.id : uuidv4();

  const sanitizedProducts = productsInCampaign.map((product) => ({
    ...product,
    discountType: product.discountType || 'percentage',
    discountValue: product.discountValue || 0,
    originalPrice: product.defaultPrice || 0,
    discountedPrice: product.discountType === 'buyXPayForY' ? null : product.discountedPrice || 0,
    buyX: product.buyX || 0,
    payForY: product.payForY || 0,
  }));

  try {
    const promoDocRef = doc(db, 'promotionalContent', id);
    const updatedData = {
      title,
      bannerImage: bannerImage || '',
      description: description || '',
      buttonText: buttonText || 'Läs mer',
      slug,
      categorySlug: categorySlug || '',
      supplierSlug: supplierSlug || '',
      products: sanitizedProducts,
    };

    await setDoc(promoDocRef, updatedData, { merge: true });

    alert('Promotion updated!');
    
    // Uppdatera promotions och synkronisera state
    const updatedPromotions = promotions.map((promo) =>
      promo.id === id ? { id, ...updatedData } : promo
    );
    setPromotions(updatedPromotions);
    resetForm();
  } catch (error) {
    console.error('Error updating promotion:', error);
    alert('Error updating promotion');
  }
};

  

  const handleDiscountChange = (index, field, value) => {
    const updatedProducts = [...productsInCampaign];
    updatedProducts[index][field] = value;
  
    const product = updatedProducts[index];
    const originalPrice = product.defaultPrice;
  
   // Hantera rabatt baserat på typ
  if (field === 'discountType' || field === 'discountValue') {
    const discountValue = parseFloat(product.discountValue || 0);

    if (product.discountType === 'percentage') {
      // Procentuell rabatt
      const discountAmount = (originalPrice * discountValue) / 100;
      product.discountedPrice = Math.max(originalPrice - discountAmount, 0); // Minsta värde 0
    } else if (product.discountType === 'fixed') {
      // Fast belopp
      product.discountedPrice = Math.max(originalPrice - discountValue, 0); // Minsta värde 0
    } else if (product.discountType === 'buyXPayForY') {
      // För "Köp X Betala för Y", sätt discountedPrice till null
      product.discountedPrice = null;
    }
  }
  
    // Hantera "Köp X betala för Y"-fält
    if (field === 'buyX' || field === 'payForY') {
      product.buyX = parseInt(product.buyX || 0, 10);
      product.payForY = parseInt(product.payForY || 0, 10);
    }
  
    setProductsInCampaign(updatedProducts);
  };
  
  

  const resetForm = () => {
    setBannerImage('');
    setDescription('');
    setButtonText('');
    
    setCategorySlug('');
    setSupplierSlug('');
    setSelectedPromotion(null);
    setProductsInCampaign([]);
  };

  
  const handleEdit = (promotion) => {
    setTitle(promotion.title || '');
    setBannerImage(promotion.bannerImage);
    setDescription(promotion.description);
    setButtonText(promotion.buttonText);
    setSlug(promotion.slug || '');
    setCategorySlug(promotion.categorySlug);
    setSupplierSlug(promotion.supplierSlug);
    setSelectedPromotion(promotion);
  
    // Hantera produkter i kampanjen
    if (promotion.products && promotion.products.length > 0) {
      const loadedProducts = promotion.products.map((product) => ({
        ...product,
        originalPrice: product.defaultPrice || 0,
        discountType: product.discountType || 'percentage',
        discountValue: product.discountValue || 0,
        buyX: product.buyX || 0, // Standardvärde för buyX
        payForY: product.payForY || 0, // Standardvärde för payForY
        discountedPrice: product.discountedPrice || 0,
      }));
      setProductsInCampaign(loadedProducts);
    } else {
      setProductsInCampaign([]);
    }
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = productsInCampaign.filter((_, i) => i !== index);
    setProductsInCampaign(updatedProducts);
  };
  
  const handleSaveProduct = (index) => {
    const updatedProducts = [...productsInCampaign];
    const product = updatedProducts[index];
    // Här kan du lägga till logik om du vill spara produktändringarna till Firestore separat, om så önskas
    alert(`Produkt ${product.description} har uppdaterats med ny rabatt!`);
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'promotionalContent', id));
      setPromotions(promotions.filter((promo) => promo.id !== id));
      alert('Promotion deleted!');
    } catch (error) {
      console.error('Error deleting promotion:', error);
      alert('Error deleting promotion');
    }
  };

  return role === 'admin' ? (
    <div className='mt-48 container mx-auto'>
      <h2 className="text-2xl font-bold my-4 ">Aktiva kampanjer:</h2>
      {/* Lista alla promotions */}
      <div className='m-5 p-8 border-2'> <ul>
          {promotions.map((promo) => (
            <li key={promo.id} className="my-2">
              <span>{promo.title || promo.description}</span>
              {/* Visa titeln om den finns, annars beskrivning */}
              <button className="mx-2 text-blue-500" onClick={() => handleEdit(promo)}>
                Edit
              </button>
              <button className="mx-2 text-red-500" onClick={() => handleDelete(promo.id)}>
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
      {/* Formulär för att lägga till eller uppdatera promotions */}
      <h2 className='text-2xl font-bold my-4'>Redigera kampanj:</h2>
      <div className='m-5 p-8 border-2'> <form onSubmit={handleSubmit} className="mt-10">
        {/* Fält för titel */}
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="title">
            Titel för kampanjen
          </label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="bannerImage">
            Banner Image URL
          </label>
          <input
            id="bannerImage"
            type="text"
            value={bannerImage}
            onChange={(e) => setBannerImage(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="description">
            Description
          </label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="4"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="buttonText">
            Button Text
          </label>
          <input
            id="buttonText"
            type="text"
            value={buttonText}
            onChange={(e) => setButtonText(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
      
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="categorySlug">
            Category Slug
          </label>
          <input
            id="categorySlug"
            type="text"
            value={categorySlug}
            onChange={(e) => setCategorySlug(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2" htmlFor="supplierSlug">
            Supplier Slug
          </label>
          <input
            id="supplierSlug"
            type="text"
            value={supplierSlug}
            onChange={(e) => setSupplierSlug(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">
            Lägg till Produkt (Sök på artikelnummer eller produktnamn)
          </label>
          <input
            type="text"
            placeholder="Skriv produktens artikelnummer eller namn"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        {searchResults.length > 0 && (
          <div className="mb-4">
            <ul>
              {searchResults.map((product, index) => (
                <li key={index}>
                  {product.description} (Artikelnummer: {product.inventoryNumber})
                  <button
                    type="button"
                    className="ml-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => addProductToCampaign(product)}
                  >
                    Lägg till
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}


        {selectedProduct && (
          <div>
            <p>{selectedProduct.name} (Originalpris: {selectedProduct.price} kr)</p>
            
            <div className="mb-4">
              
              <label className="block text-gray-700 dark:text-white text-sm font-bold mb-2">Rabatt</label>
              <select value={discountType} onChange={(e) => setDiscountType(e.target.value)}>
                <option value="percentage">Procent</option>
                <option value="fixed">Fast pris</option>
              </select>
              <input
                type="number"
                placeholder="Rabattvärde"
                value={discountValue}
                onChange={(e) => setDiscountValue(e.target.value)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <button
                type="button"
                onClick={addProductToCampaign}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-2"
              >
                Lägg till Produkt
              </button>
            </div>
          </div>
        )}

        {/* Lista över tillagda produkter */}
       {/* Lista över tillagda produkter */}
         {/* Lista över tillagda produkter */}
         <div>
          <h3>Tillagda Produkter:</h3>
             {/* Lista över tillagda produkter som tabell */}
        {productsInCampaign.length > 0 && (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white dark:bg-gray-800">
              <thead>
                <tr>
                  <th className="px-4 py-2">Artikelnummer</th>
                  <th className="px-4 py-2">Namn</th>
                  <th className="px-4 py-2">Rabatt-typ</th>
                  <th className="px-4 py-2">Rabattvärde</th>
                  <th className="px-4 py-2">Originalpris</th>
                  <th className="px-4 py-2">Rabatterat pris</th>
                  <th className="px-4 py-2">Spara/Uppdatera</th>
                  <th className="px-4 py-2">Ta bort</th>
                </tr>
              </thead>
              <tbody>
  {productsInCampaign.map((product, index) => (
    <tr key={index}>
      <td className="border px-4 py-2">{product.inventoryNumber}</td>
      <td className="border px-4 py-2">{product.description}</td>
      <td className="border px-4 py-2">
        <select
          value={product.discountType}
          onChange={(e) => handleDiscountChange(index, 'discountType', e.target.value)}
          className="border rounded px-2 py-1"
        >
          <option value="percentage">Procentuell rabatt</option>
          <option value="fixed">Fast belopp</option>
          <option value="buyXPayForY">Köp X Betala för Y</option> {/* Nytt alternativ */}
        </select>
      </td>
      {/* Fält för rabattvärde */}
      <td className="border px-4 py-2">
        {product.discountType === 'buyXPayForY' ? (
          // Lägg till "Köp X Betala för Y"-fälten här
          <div className="flex flex-col">
            <label htmlFor={`buyX-${index}`} className="text-sm font-medium">
              Köp X
            </label>
            <input
              id={`buyX-${index}`}
              type="number"
              value={product.buyX || ''}
              onChange={(e) => handleDiscountChange(index, 'buyX', e.target.value)}
              className="border rounded px-2 py-1"
            />
            <label htmlFor={`payForY-${index}`} className="text-sm font-medium mt-2">
              Betala för Y
            </label>
            <input
              id={`payForY-${index}`}
              type="number"
              value={product.payForY || ''}
              onChange={(e) => handleDiscountChange(index, 'payForY', e.target.value)}
              className="border rounded px-2 py-1"
            />
          </div>
        ) : (
          <input
            type="number"
            value={product.discountValue}
            onChange={(e) => handleDiscountChange(index, 'discountValue', e.target.value)}
            className="border rounded px-2 py-1 w-full"
            placeholder={
              product.discountType === 'percentage'
                ? 'Rabatt i procent'
                : 'Rabatt i kronor'
            }
          />
        )}
      </td>
      <td className="border px-4 py-2">{product.defaultPrice} kr</td>
      <td className="border px-4 py-2">
      {product.discountedPrice !== null && typeof product.discountedPrice === 'number' ? (
    `${product.discountedPrice.toFixed(2)} kr`
  ) : (
    `${product.defaultPrice.toFixed(2)} kr`
  )}
</td>

      <td className="border px-4 py-2">
        <button
          type="button"
          onClick={() => handleSaveProduct(index)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Spara/Uppdatera
        </button>
      </td>
      <td className="border px-4 py-2">
        <button
          type="button"
          onClick={() => handleRemoveProduct(index)}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
        >
          Ta bort
        </button>
      </td>
    </tr>
  ))}
</tbody>

            </table>
          </div>
        )}
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          {selectedPromotion ? 'Update Promotion' : 'Add Promotion'}
        </button>
        {selectedPromotion && (
          <button
            type="button"
            onClick={resetForm}
            className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
          >
            Cancel
          </button>
        )}
      </form>
      </div>
    </div>
  ) : null;
};

export default PromotionalEditForm;
