// Layout.js
import React from 'react';
import Klippvaror from '../Products/Klippvaror';
import Sasong from '../Products/Sasong';
import { useAuth } from "../context/authContext";
import Kampanj from '../components/Kampanj';
import Login from '../Users/Login';
import DiscountNotification from '../components/DiscountNotification';
import PromotionalContent from '../components/PromotionalContent';
import HighlightedProducts from '../Products/HighlightedProducts';
const ShopStart = () => {
  const { currentUser } = useAuth();
  const isAdmin = currentUser?.role === 'admin'; // Kontrollera om användaren är admin
  const productLimit = 8; // Exempel: Visa endast 8 produkter på startsidan



  
  return (
    <div className="mt-24 pt-24">
      
      {currentUser ? (
        <><DiscountNotification />
      <PromotionalContent />
      <Kampanj />
       
      {/* Rendera alla markerade produkter utan kategori */}
      <HighlightedProducts showForLandingPage={true} />
         
         
          
          <Sasong limit={productLimit} />
          <Klippvaror limit={productLimit} />
        </>
      ) : (<>
        <p className="text-2xl font-bold text-center pt-10 dark:text-white">Vänligen logga in för att se innehållet.</p>
        <Login />
       </>
      )}
    </div>
  );
};

export default ShopStart;
