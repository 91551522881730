import React, { createContext, useState, useEffect, useContext } from 'react';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { authentication, db } from '../firebase/firebase';
import { collection, query, where, getDocs, or } from 'firebase/firestore';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const mapAuthError = (error) => {
    switch (error.code) {
      case 'auth/invalid-email':
        return 'Ogiltig e-postadress.';
      case 'auth/user-disabled':
        return 'Detta konto har inaktiverats.';
      case 'auth/user-not-found':
        return 'Ingen användare hittades med denna e-postadress.';
      case 'auth/wrong-password':
        return 'Fel lösenord.';
      default:
        return error.message;
    }
  };

  // Logga in och kontrollera adminstatus
  const login = async (identifier, password) => {
    try {
      const isEmail = identifier.includes('@');
      let email = identifier;
      let userDoc = null;

      if (!isEmail) {
        const identifierAsString = String(identifier);
        const usersRef = collection(db, 'customers');
        const queryByNumber = query(usersRef, where('number', '==', identifierAsString));
        const querySnapshotByNumber = await getDocs(queryByNumber);

        if (!querySnapshotByNumber.empty) {
          userDoc = querySnapshotByNumber.docs[0];
        } else {
          const queryByOldNumber = query(usersRef, where('attributes', 'array-contains', {
            id: 'CUST02',
            value: identifierAsString
          }));
          const querySnapshotByOldNumber = await getDocs(queryByOldNumber);
          if (!querySnapshotByOldNumber.empty) {
            userDoc = querySnapshotByOldNumber.docs[0];
          }
        }

        if (userDoc) {
          email = userDoc.data().mainContact.email;
        } else {
          throw new Error('Kundnummer kunde inte hittas.');
        }
      }

  // Logga in användaren med Firebase Authentication
  const { user } = await signInWithEmailAndPassword(authentication, email, password);
  setCurrentUser(user);

  // Hämta kopplat kunddokument (huvud- eller sekundärt konto)
  const customerData = await fetchCustomerData(user.uid);
  setUserData(customerData);

  // Kontrollera och sätt adminstatus
  await checkAndAssignAdminRole(user.uid);

  setError(null);
} catch (error) {
  setError(mapAuthError(error));
}
};

const fetchCustomerData = async (authUID) => {
  console.log('Fetching customer data for authUID:', authUID);

  try {
    const customersRef = collection(db, 'customers');

    // 1. Kontrollera om authUID är kopplat till ett huvudkonto
    const primaryQuery = query(customersRef, where('authUID', '==', authUID));
    const primarySnapshot = await getDocs(primaryQuery);

    if (!primarySnapshot.empty) {
      console.log('Primary account found for authUID:', authUID);
      const customerDoc = primarySnapshot.docs[0];
      return { id: customerDoc.id, ...customerDoc.data() };
    }

    // 2. Kontrollera om authUID finns i accounts manuellt
    console.log('Primary account not found, checking secondary accounts for authUID:', authUID);

    const allCustomersSnapshot = await getDocs(customersRef);

    for (const doc of allCustomersSnapshot.docs) {
      const customerData = doc.data();

      // Kontrollera om accounts innehåller authUID
      if (customerData.accounts) {
        const accountMatch = customerData.accounts.find(account => account.authUID === authUID);
        if (accountMatch) {
          console.log('Secondary account found in customer document:', doc.id);
          return { id: doc.id, ...customerData };
        }
      }
    }

    // 3. Om ingen matchning hittas
    console.error('No customer data found for authUID:', authUID);
    throw new Error('Kunddata kunde inte hittas för detta konto.');
  } catch (error) {
    console.error('Error in fetchCustomerData:', error);
    return null;
  }
};



  
const checkAndAssignAdminRole = async (uid) => {
  try {
    // Kontrollera om användaren har rollen "admin" i userData
    if (userData?.role !== 'admin') {
      console.log('User is not an admin, skipping role check.');
      setIsAdmin(false); // Sätt adminstatus till false om inte admin
      return;
    }

    console.log('Checking and assigning admin role for admin user with authUID:', uid);

    // Anropa API för att tilldela admin-roll
    await axios.post('/api/setAdminRole', 
      { uid },
      { withCredentials: true } // Om cookies används
    );

    // Kontrollera om admin-claim är tilldelad
    const response = await axios.get('/api/checkAdminRole', { 
      params: { uid },
      withCredentials: true 
    });

    const isAdmin = response.data.isAdmin;
    setIsAdmin(isAdmin); // Uppdatera adminstatus

    // Uppdatera användarens token för att inkludera nya claims
    const user = authentication.currentUser;
    if (user) {
      await user.getIdToken(true); // Uppdatera token
    }

    console.log('Admin role checked and updated:', isAdmin);
  } catch (error) {
    console.error('Error checking and setting admin role:', error);
    setIsAdmin(false); // Om något går fel, sätt adminstatus till false
  }
};



  const logout = async () => {
    try {
      await signOut(authentication);
      setIsAdmin(false); // Nollställ adminstatus vid utloggning
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(authentication, async (user) => {
      setLoading(true);
  
      if (user) {
        console.log('User logged in with authUID:', user.uid);
  
        // Initialt sätt Firebase user till `currentUser`
        setCurrentUser(user);
  
        try {
          // Hämta kopplat kunddokument (huvud- eller sekundärt konto)
          const customerData = await fetchCustomerData(user.uid);
          setUserData(customerData);
  
          if (customerData) {
            console.log('Customer data found:', customerData);
  
            // Berika `currentUser` med kunddata
            setCurrentUser((prevUser) => ({
              ...prevUser,
              customerId: customerData.id,
              customerName: customerData.mainContact?.name || 'Okänd kund',
              customerEmail: customerData.mainContact?.email || prevUser.email,
              role: customerData.role || 'user',
            }));
          } else {
            console.warn('No customer data found, currentUser will not include customer details.');
          }
  
          // Kontrollera adminstatus
          await checkAndAssignAdminRole(user.uid);
        } catch (error) {
          console.error('Error fetching customer data:', error);
          setUserData(null);
          setCurrentUser(user); // Behåll endast Firebase user om kunddata inte hittas
        }
      } else {
        console.log('No user logged in.');
  
        // Nollställ all data vid utloggning
        setCurrentUser(null);
        setUserData(null);
        setIsAdmin(false);
      }
  
      setLoading(false);
    });
  
    return unsubscribe;
  }, []);
  
  
  
  
  

  return (
    <AuthContext.Provider value={{ currentUser, login, logout, error, isAdmin, userData }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
