import React, { createContext, useContext, useReducer, useEffect, useCallback, useState } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, logTrace } from '../firebase/firebase';
import { useUserContext } from './userContext';
import { loadFirestoreBundleAndQuery, fetchProductsFromJSON, loadCategoriesAndSubCategories, listenToStockUpdates, getProductsFromIndexedDB, listenToProductUpdates, saveProductsToIndexedDB, saveMetaToIndexedDB, getMetaFromIndexedDB, getIndexedDBInstance } from '../firebase/firebase';

const ProductContext = createContext();
export const useProductContext = () => useContext(ProductContext);





const initialState = {
    products: [],
    discountedProducts: [],
    klippvaror: [],
    mainCategories: [],
    subCategories: [],
    kampanjProducts: [],
    discounts: [],
    supplierData: [],
    promotionalContent: [],
    stockLevels: {}, // lagerstatus från productStock
    searchQuery: '',
    loading: true,
};

// Reducer-funktion för hantering av state-uppdateringar
const productReducer = (state, action) => {
    switch (action.type) {
        case 'SET_PRODUCTS':
            // Säkerställ att det inte finns dubbletter baserat på `id`
            const uniqueProducts = Array.from(
                new Map(action.payload.map((product) => [product.inventoryNumber, product])).values()
            );
            return { ...state, products: uniqueProducts };
            case 'UPDATE_PRODUCTS':
    const updatedMap = new Map(action.payload.map((product) => [product.inventoryNumber, product]));
    const updatedProducts = state.products.map((product) =>
        updatedMap.has(product.inventoryNumber)
            ? { ...product, ...updatedMap.get(product.inventoryNumber) }
            : product
    );
    console.log('Updaterade produkter i state:', updatedProducts);
    return { ...state, products: updatedProducts };
    case 'UPDATE_STOCK':
        const normalizedStock = Array.isArray(action.payload)
          ? action.payload.reduce((acc, stockItem) => {
              acc[stockItem.inventoryId] = stockItem; // Använd `inventoryId` som nyckel
              return acc;
            }, {})
          : { ...action.payload }; // Hantera om payload redan är ett objekt
      
        return {
          ...state,
          stockLevels: {
            ...state.stockLevels,
            ...normalizedStock, // Slå samman med befintliga stockLevels
          },
          products: state.products.map((product) => ({
            ...product,
            stockLevel: normalizedStock[product.inventoryNumber]?.quantityOnHand || product.stockLevel, // Uppdatera lagerstatus
          })),
        };
        case 'SET_DISCOUNTED_PRODUCTS':
            return { ...state, discountedProducts: action.payload };
            case 'SET_KLIPPVAROR': // Lägg till hantering av klippvaror
            return { ...state, klippvaror: action.payload };
        case 'SET_MAIN_CATEGORIES':
            return { ...state, mainCategories: action.payload };

        case 'SET_SUPPLIER_DATA':
            return { ...state, supplierData: action.payload };

        case 'SET_PROMOTIONAL_CONTENT':
            return { ...state, promotionalContent: action.payload };

        case 'SET_LOADING':
            return { ...state, loading: action.payload };

            case 'SET_SEARCH_QUERY':
                return { ...state, searchQuery: action.payload }; // Uppdatera sökfrågan i state
    
        default:
            return state;
    }
};



export const ProductProvider = ({ children }) => {
    const [state, dispatch] = useReducer(productReducer, initialState);
    const { userData } = useUserContext();
    const [shouldReload, setShouldReload] = useState(false); // Ny state-variabel för att trigga omladdning
    const setSearchQuery = (query) => dispatch({ type: 'SET_SEARCH_QUERY', payload: query });

    const stringToSlug = (str) => {
        return str.toLowerCase()
            .replace(/[åä]/g, 'a')
            .replace(/[ö]/g, 'o')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')
            .trim();
    };



    const supplierPrefixes = {
        "gran": "", // Tar bort "Gran" från produktnamn
        "ets": "",  // Tar bort "Eco" från produktnamn
        "leo": "", // Tar bort "Super" från produktnamn
        "alma": "", // Tar bort "Super" från produktnamn
        "st": "", // Tar bort "Gran" från produktnamn
        "LC": "",  // Tar bort "Eco" från produktnamn
        "belb": "", // Tar bort "Super" från produktnamn
        "bel": "", // Tar bort "Super" från produktnamn
        "fallot": "", // Tar bort "Gran" från produktnamn
        "savini": "",  // Tar bort "Eco" från produktnamn
        "nonna": "", // Tar bort "Super" från produktnamn
        "granata": "", // Tar bort "Super" från produktnamn
        "bont": "", // Tar bort "Gran" från produktnamn
        "arti": "",  // Tar bort "Eco" från produktnamn
        "db": "", // Tar bort "Super" från produktnamn
        "val": "", // Tar bort "Super" från produktnamn
        "cart": "", // Tar bort "Gran" från produktnamn
        "dunc": "",  // Tar bort "Eco" från produktnamn
        "far": "", // Tar bort "Super" från produktnamn
        "deli": "", // Tar bort "Super" från produktnamn
        "biscu": "", // Tar bort "Super" från produktnamn
        "bruyere": "", // Tar bort "Gran" från produktnamn
        "whit": "",  // Tar bort "Eco" från produktnamn
        "dhau": "", // Tar bort "Super" från produktnamn
        "pau": "", // Tar bort "Super" från produktnamn
        "belli": "", // Tar bort "Gran" från produktnamn
        "sierra": "",  // Tar bort "Eco" från produktnamn
        "lc": "", // Tar bort "Super" från produktnamn
        "torres": "", // Tar bort "Super" från produktnamn
        "nor": "", // Tar bort "Gran" från produktnamn
        "lat": "",  // Tar bort "Eco" från produktnamn
        "lap": "", // Tar bort "Super" från produktnamn
        "ven": "", // Tar bort "Super" från produktnamn
        "ib": "", // Tar bort "Gran" från produktnamn
        "campo": "",  // Tar bort "Eco" från produktnamn
        "fancy": "", // Tar bort "Super" från produktnamn
        "elavion": "", // Tar bort "Super" från produktnamn
        "corex": "", // Tar bort "Super" från produktnamn
        "anko": "", // Tar bort "Gran" från produktnamn
        "ducs": "",  // Tar bort "Eco" från produktnamn
        "bm": "", // Tar bort "Super" från produktnamn
        "nomu": "", // Tar bort "Super" från produktnamn
        "munch": "", // Tar bort "Gran" från produktnamn
        "green": "",  // Tar bort "Eco" från produktnamn
        "food": "", // Tar bort "Super" från produktnamn
        "and": "", // Tar bort "Super" från produktnamn
        "mrsd": "", // Tar bort "Gran" från produktnamn
        "don": "",  // Tar bort "Eco" från produktnamn
        "atk": "", // Tar bort "Super" från produktnamn
        "lacasa": "", // Tar bort "Super" från produktnamn
        "brez": "", // Tar bort "Gran" från produktnamn
        "can": "",  // Tar bort "Eco" från produktnamn
        "australian": "", // Tar bort "Super" från produktnamn
        "thu": "", // Tar bort "Super" från produktnamn
        "ml": "", // Tar bort "Gran" från produktnamn
        "baru": "",  // Tar bort "Eco" från produktnamn
        "åhus": "", // Tar bort "Super" från produktnamn
        "mac": "", // Tar bort "Super" från produktnamn
        "effervé": "", // Tar bort "Gran" från produktnamn
        "orna": "",  // Tar bort "Eco" från produktnamn
        "himmelstund": "", // Tar bort "Super" från produktnamn
        "rscued": "", // Tar bort "Super" från produktnamn
        "rubio": "", // Tar bort "Gran" från produktnamn
        "pop": "",  // Tar bort "Eco" från produktnamn
        "comptoir": "", // Tar bort "Super" från produktnamn
        "choc": "", // Tar bort "Super" från produktnamn
        "choco": "", // Tar bort "Super" från produktnamn
        "luigia": "", // Tar bort "Super" från produktnamn
        "riv": "", // Tar bort "Gran" från produktnamn
        "caf": "",  // Tar bort "Eco" från produktnamn
        "ct": "", // Tar bort "Super" från produktnamn
        "gar": "", // Tar bort "Super" från produktnamn
        "house": "", // Tar bort "Gran" från produktnamn
        "prez": "",  // Tar bort "Eco" från produktnamn
        "coll": "", // Tar bort "Super" från produktnamn
        "mont": "", // Tar bort "Super" från produktnamn
        "gk": "", // Tar bort "Gran" från produktnamn
        "kk": "",  // Tar bort "Eco" från produktnamn
        "amatller": "", // Tar bort "Super" från produktnamn
        "rj": "", // Tar bort "Super" från produktnamn
        "gal": "", // Tar bort "Super" från produktnamn
        "kent": "", // Tar bort "Super" från produktnamn
        // Lägg till fler prefix efter behov
    };

    const removeSupplierPrefix = (title, prefixes) => {
        if (!title) return title;
        const words = title.split(" ");
        const firstWord = words[0].trim().toLowerCase();
       // console.log('Original title:', title);
       // console.log('Words:', words, 'FirstWord (lowercase):', firstWord);
    
        // Kontrollera om firstWord matchar någon nyckel
       // console.log('Checking if', firstWord, 'exists in prefixes:', Object.keys(prefixes));
        if (prefixes[firstWord] !== undefined) {
           // console.log(`Prefix "${firstWord}" matchat. Tar bort det.`);
            return words.slice(1).join(" ");
        }
    
   //     console.log(`Ingen match för prefix "${firstWord}".`);
        return title;
    };
    const checkAndUpdateData = async () => {
        try {
          // Hämta version från servern
          const response = await fetch('/api/app-version');
          const { version: serverVersion } = await response.json();
      
          // Hämta version från IndexedDB
          const clientVersion = await getMetaFromIndexedDB('version');
      
          if (clientVersion !== serverVersion) {
            console.log('Version mismatch, uppdaterar IndexedDB-data...');
      
            // Rensa IndexedDB
            const indexedDB = await getIndexedDBInstance(); // Uppdatering här
            await indexedDB.clear('products');
      
            // Hämta och spara om data
            const products = await fetchProductsFromJSON();
            await saveProductsToIndexedDB(products);
      
            // Uppdatera version i IndexedDB
            await saveMetaToIndexedDB('version', serverVersion);
      
            console.log('IndexedDB uppdaterad till version:', serverVersion);
          } else {
            console.log('IndexedDB är redan uppdaterad till version:', clientVersion);
          }
        } catch (error) {
          console.error('Fel vid kontroll av datauppdatering:', error);
        }
      };
      
      
      // Kör kontroll vid appstart
      checkAndUpdateData();
      
      useEffect(() => {
        checkAndUpdateData();
      }, []);
      
            
    
      useEffect(() => {
        let unsubscribe = null;
      
        const manageStockUpdates = async () => {
          const indexedDB = await getIndexedDBInstance();
          const cachedStock = (await indexedDB.get('stock', 'productStock'))?.data;
      
          if (cachedStock) {
            console.log('Laddar stock från IndexedDB.');
            dispatch({ type: 'UPDATE_STOCK', payload: cachedStock });
          }
      
          if (userData) {
            console.log("Användare inloggad. Startar listenToStockUpdates.");
            unsubscribe = await listenToStockUpdates((updatedStocks) => {
              if (Object.keys(updatedStocks).length > 0) {
                dispatch({ type: 'UPDATE_STOCK', payload: updatedStocks });
              }
            });
          }
        };
      
        manageStockUpdates();
      
        return () => {
          if (unsubscribe) {
            console.log("Avslutar listenToStockUpdates.");
            unsubscribe();
          }
        };
      }, [userData]);

    useEffect(() => {
        let unsubscribe;
        const setupListener = async () => {
          unsubscribe = await listenToProductUpdates((updatedProducts) => {
            console.log("Uppdaterade produkter från updatedProducts:", updatedProducts);
      
            // Uppdatera state med de nya produkterna
            dispatch({ type: 'UPDATE_PRODUCTS', payload: updatedProducts });
          });
        };
      
        setupListener();
      
        return () => {
          if (unsubscribe) {
            unsubscribe();
          }
        };
      }, []);
      
    



      const combinePromotionsWithProducts = (products, promotions) => {
        return products.map((product) => {
          const promo = promotions.find((promo) =>
            promo.products.some((p) => p.inventoryNumber === product.inventoryNumber)
          );
      
          if (promo) {
            const promoData = promo.products.find((p) => p.inventoryNumber === product.inventoryNumber);
            return {
              ...product,
              discountType: promoData.discountType,
              buyX: promoData.buyX,
              payForY: promoData.payForY,
              campaignName: promo.title, // Lägg till kampanjnamn
              isCampaignProduct: true, // Flagga för att identifiera kampanjprodukter
              discountedPrice: promoData.discountedPrice
            };
          }
      
          return product;
        });
      };


      const fetchPromotionalContent = async () => {
        const traceName = "fetchPromotionalContent";
        let readOperations = 0;
    
        try {
            const promotionsRef = collection(db, 'promotionalContent');
            const promotionSnapshot = await getDocs(promotionsRef);
            readOperations += promotionSnapshot.docs.length;
    
            const promotionData = promotionSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
    
            // Uppdatera state med hämtad data
            dispatch({ type: 'SET_PROMOTIONAL_CONTENT', payload: promotionData });
    
            // Logga framgångsrik trace
            logTrace(traceName, {
                readOperations,
                fetchedPromotions: promotionData.length,
            });
        } catch (error) {
            console.error('Error fetching promotionalContent:', error);
    
            // Logga fel
            logTrace(traceName, {
                readOperations,
                errors: 1,
            });
        }
    };
    const fetchGeneralData = async () => {
        dispatch({ type: 'SET_LOADING', payload: true });
    
        try {
            // Hämta produkter från IndexedDB eller JSON
            const cachedProducts = await getProductsFromIndexedDB();
            let processedProducts = [];
    
            if (!cachedProducts) {
                const productsFromJSON = await fetchProductsFromJSON();
                processedProducts = await processProducts(productsFromJSON);
                await saveProductsToIndexedDB(processedProducts);
            } else {
                processedProducts = await processProducts(cachedProducts);
            }
    
            dispatch({ type: 'SET_PRODUCTS', payload: processedProducts });
    
            // Återanvänd `fetchPromotionalContent` för att hämta promotions
            await fetchPromotionalContent();
    
            // Kombinera produkter med promotions från state
            const promotions = state.promotionalContent || [];
            const combinedProducts = combinePromotionsWithProducts(processedProducts, promotions);
    
            // Uppdatera IndexedDB och state med kombinerade produkter
            await saveProductsToIndexedDB(combinedProducts);
            dispatch({ type: 'SET_PRODUCTS', payload: combinedProducts });
    
            // Ladda kategorier och leverantörsdata
            const categories = await loadCategoriesAndSubCategories();
            dispatch({ type: 'SET_MAIN_CATEGORIES', payload: categories });
    
            const supplierData = processUniqueSupplierData(combinedProducts);
            dispatch({ type: 'SET_SUPPLIER_DATA', payload: supplierData });
    
        } catch (error) {
            console.error("Error fetching general data:", error);
        } finally {
            dispatch({ type: 'SET_LOADING', payload: false });
        }
    };
    
    
    
      
      
    
    
// Funktion för att bearbeta en produkt
const processProduct = (product) => ({
    ...product,
    description: removeSupplierPrefix(product.description, supplierPrefixes),
    salesCategories: product.salesCategories.map((category) => ({
        ...category,
        slug: stringToSlug(category.description),
    })),
    supplierDetails: product.supplierDetails.map((supplier) => ({
        ...supplier,
        slug: stringToSlug(supplier.supplierName),
    })),
});

// Funktion för att slå samman befintliga och nya produkter
const mergeProducts = (cachedProducts, newProducts) => {
    return cachedProducts
        ? [...cachedProducts.filter(
            (prod) => !newProducts.find((updated) => updated.id === prod.id)
          ), ...newProducts]
        : newProducts;
};
    
    
    
    
    
    
    

const processUniqueSupplierData = (productsData) => {
 //   console.log("processUniqueSupplierData: Startar bearbetning av leverantörsdata från produkter", productsData);
    const supplierSet = new Set();

    const suppliers = productsData.flatMap(product => {
        return product.supplierDetails.map(supplier => {
            const supplierKey = `${supplier.supplierName}-${supplier.supplierId}`;
            if (!supplierSet.has(supplierKey)) {
                supplierSet.add(supplierKey);
                return {
                    supplierName: supplier.supplierName,
                    supplierId: String(supplier.supplierId)
                };
            }
            return null;
        });
    }).filter(supplier => supplier !== null);

 //   console.log("processUniqueSupplierData: Unika leverantörer bearbetade:", suppliers);
    return suppliers;
};


    const processProducts = async (loadedProducts) => {
        return loadedProducts.map(product => {
            const salesCategoriesWithSlug = product.salesCategories.map(category => ({
                ...category,
                slug: stringToSlug(category.description)
            }));
            const supplierDetailsWithSlug = product.supplierDetails.map(supplier => ({
                ...supplier,
                slug: stringToSlug(supplier.supplierName)
            }));

            const titleWithoutPrefix = removeSupplierPrefix(product.description, supplierPrefixes);
          //  console.log("Original description:", product.description, "Processed title:", titleWithoutPrefix);
            return {
                ...product,
                description: titleWithoutPrefix,
                salesCategories: salesCategoriesWithSlug,
                supplierDetails: supplierDetailsWithSlug
            };
        }).filter(product => 
            (product.status === 'Active' || product.status === 'NoSales') &&
            product.attributes.some(attribute => attribute.id === "PRODWEB" && attribute.value === "1")
        );
    };

   
    const applyCustomerDiscounts = async (productsData, userCustomerNumber) => {
        const traceName = "applyCustomerDiscounts";
        let readOperations = 0;
    
        try {
            const discountsRef = collection(db, 'discounts');
            const discountsSnapshot = await getDocs(discountsRef);
            readOperations += discountsSnapshot.docs.length;
    
            let applicableDiscount = null;
    
            // Hitta tillämplig rabatt baserat på användarens kundnummer
            discountsSnapshot.docs.forEach(doc => {
                const discountData = doc.data();
                if (Array.isArray(discountData.customers) &&
                    discountData.customers.some(customerObj => customerObj.customer === userCustomerNumber)) {
                    applicableDiscount = discountData;
                }
            });
    
            // Om en rabatt finns, applicera den på produkterna
            if (applicableDiscount) {
                const discountPercent = parseInt(applicableDiscount.discountCode, 10);
                const updatedProducts = productsData.map(product => {
                    if (!product.discountedPrice && !product.isKlippvara) {
                        const discountedPrice = calculateDiscountedPrice(product.defaultPrice, discountPercent);
                        return { ...product, discountedPrice };
                    }
                    return product;
                });
    
                // Logga framgångsrik trace
                logTrace(traceName, {
                    readOperations,
                    discountedProducts: updatedProducts.length,
                });
    
                return updatedProducts;
            }
    
            // Om ingen rabatt finns, returnera originaldata och logga
            logTrace(traceName, {
                readOperations,
                discountedProducts: 0,
            });
            return productsData;
        } catch (error) {
            console.error('Error applying customer discounts:', error);
    
            // Logga fel
            logTrace(traceName, {
                readOperations,
                errors: 1,
            });
    
            return productsData;
        }
    };
    
    const calculateDiscountedPrice = (price, discountPercent) => {
        return price - (price * discountPercent / 100);
    };
    
    const processKlippvaror = async (productsData) => {
        const traceName = "processKlippvaror";
        let readOperations = 0;
        let klippvarorQuery = collection(db, 'klippvaror');
        const klippvarorSnapshot = await getDocs(klippvarorQuery);
        readOperations += klippvarorSnapshot.docs.length;
        const klippvarorData = klippvarorSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    
        const today = new Date().toISOString().split('T')[0];
    
        const combinedData = klippvarorData.map(klippvara => {
            const matchingProduct = productsData.find(product => product.inventoryNumber === klippvara.inventoryItem.number);
    
            if (matchingProduct) {
                // Kopiera och kombinera produktdata med klippvarodata
                const combinedProductData = {
                    ...matchingProduct, // Baseras på ordinarie produktdata
                    klippvarorData: { ...klippvara }, // Lägg till hela klippvarodata för eventuell referens
                    discountedPrice: klippvara.discountedPrice, // Skriv över pris för klippvaror
                    expirationDate: klippvara.expirationDate, // Lägg till bäst före datum
                    inventoryNumber: `${matchingProduct.inventoryNumber}`, // Använd ett unikt inventoryNumber för klippvaror
                    isKlippvara: true // Lägg till en flagga för att indikera att detta är en klippvara
                };
    
                // Beräkna rabatt baserat på bäst före datum
                const expirationDate = new Date(klippvara.expirationDate);
                const diffDays = Math.floor((expirationDate - new Date()) / (1000 * 60 * 60 * 24));
    
                // Logga viktiga värden för felsökning
              //  console.log(`Processing klippvara: ${klippvara.inventoryItem.number}`);
               // console.log(`Today: ${new Date().toISOString().split('T')[0]}, Expiration Date: ${klippvara.expirationDate}`);
               // console.log(`Days until expiration: ${diffDays}`);
    
                let discountPercent = 0;
                if (diffDays > 60) {
                    discountPercent = 30;
              //      console.log(`Setting discount to 30% (61-90 days)`);
                } else if (diffDays > 30) {
                    discountPercent = 50;
                //    console.log(`Setting discount to 50% (31-60 days)`);
                } else if (diffDays >= 0) {
                    discountPercent = 70;
                 //   console.log(`Setting discount to 70% (0-30 days)`);
                } else {
               //     console.log(`No discount applied (expiration already passed or invalid diffDays)`);
                }
                
    
                combinedProductData.discountedPrice = Math.round(combinedProductData.defaultPrice * (1 - discountPercent / 100));
             //   console.log(`Final discounted price: ${combinedProductData.discountedPrice}`);
    
                return combinedProductData;
            }
    
            return null;
        }).filter(Boolean);
    
        // Uppdatera state med kombinerade data
        dispatch({ type: 'SET_KLIPPVAROR', payload: combinedData });
        logTrace(traceName, { readOperations, processedKlippvaror: combinedData.length });
    };
    
    
    const filterProducts = useCallback((mainCategorySlug, selectedSupplier, subCategorySlug, selectedAttributes) => {
        const availableProducts = state.discountedProducts.length > 0 ? state.discountedProducts : state.products;
    // console.log("filterProducts: availableProducts", availableProducts);
    
        return availableProducts.filter(product => {
            
            
            if (!mainCategorySlug === 'Säsong' && !subCategorySlug === 'Säsong') {
                return !product.salesCategories.some(salesCategory => salesCategory.slug === 'Säsong');
            }
    
            let matchesCategory = true;
            let matchesSupplier = true;
            let matchesAttributes = true;
    
            if (mainCategorySlug && subCategorySlug && selectedSupplier) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug) &&
                                  product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
                matchesSupplier = product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            } else if (mainCategorySlug && subCategorySlug) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug) &&
                                  product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
            } else if (mainCategorySlug && selectedSupplier) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug);
                matchesSupplier = product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            } else if (subCategorySlug) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === subCategorySlug);
            } else if (mainCategorySlug) {
                matchesCategory = product.salesCategories.some(salesCategory => salesCategory.slug === mainCategorySlug);
            } else if (selectedSupplier) {
                matchesSupplier = product.supplierDetails.some(supplier => supplier.supplierName === selectedSupplier);
            }
    
            if (Array.isArray(selectedAttributes) && selectedAttributes.length > 0) {
                matchesAttributes = selectedAttributes.every(attr => 
                    product.attributes && 
                    Array.isArray(product.attributes) && 
                    product.attributes.some(attribute => attribute.id === attr && attribute.value === '1')
                );
            }

          
    
            return matchesCategory && matchesSupplier && matchesAttributes;
        });
    }, [state]); // Ändrad dependency array till `state`
    

    useEffect(() => {
        const fetchData = async () => {
          const unsubscribe = await fetchGeneralData();
      
          if (userData) {
            await fetchPromotionalContent();
            await processKlippvaror(state.products);
      
            const discountedProducts = await applyCustomerDiscounts(state.products, userData.number);
            dispatch({ type: 'SET_DISCOUNTED_PRODUCTS', payload: discountedProducts });
       //     console.log('discounted Products',discountedProducts)
          }
      
          return unsubscribe;
        };
      
        const unsubscribePromise = fetchData();
      
        return () => {
          unsubscribePromise.then((unsubscribe) => {
            if (typeof unsubscribe === 'function') {
              unsubscribe();
            }
          });
        };
      }, [userData]); // Begränsa beroenden
      
    

    return (
        <ProductContext.Provider value={{
            ...state,
            setSearchQuery,
            filterProducts,
            stockLevels: state.stockLevels, // Gör stockLevels tillgängligt
             // Exportera funktionen
        }}>
            {children}
        </ProductContext.Provider>
    );
};
