import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../context/userContext'; // Importera din UserContext
import { doc, updateDoc, query, where, collection, getDocs } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';
import { db } from '../../firebase/firebase'; // Importera din firebase-konfiguration
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth'; // Importera updatePassword-funktionen från Firebase
import { Link } from 'react-router-dom';
import PromotionalEditForm from '../../components/PromotionalEditForm'; // Importera PromotionalEditForm
import { encryptData, decryptData } from '../../services/cryptoUtils'; // Importera krypteringsfunktioner
import LinkedAccounts from './LinkedAccounts';


const UserDetails = () => {
  const { userData, setUserData, currentUser, user, discountInfo, role } = useUserContext();
  const [currentPassword, setCurrentPassword] = useState('');
  const [emailConsent, setEmailConsent] = useState(false);
  const [catalogPreference, setCatalogPreference] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Kontrollera om userData finns och hantera krypterad/okrypterad data
    if (userData) {
      const marketingAttribute = userData.attributes?.find(attr => attr.id === 'MARKNAD');
      const catalogAttribute = userData.attributes?.find(attr => attr.id === 'KATALOG');
      setEmailConsent(marketingAttribute && marketingAttribute.value === '1');
      setCatalogPreference(catalogAttribute ? catalogAttribute.value : '');
    }
  }, [userData]);


  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
  
    if (newPassword !== confirmPassword) {
      setError("Nya lösenordet matchar inte bekräftelselösenordet.");
      return;
    }
  
    if (!user) {
      setError("Ingen användare är inloggad.");
      return;
    }
  
    try {
      await updatePassword(user, newPassword);
      setSuccess("Lösenordet har uppdaterats framgångsrikt.");
    } catch (error) {
      if (error.code === "auth/requires-recent-login") {
        // Ber om nuvarande lösenord för om-inloggning
        const currentPassword = prompt("Ange ditt nuvarande lösenord för att bekräfta din identitet:");
  
        if (currentPassword) {
          const credential = EmailAuthProvider.credential(user.email, currentPassword);
  
          try {
            await reauthenticateWithCredential(user, credential);
            // Försök igen att uppdatera lösenordet efter om-inloggning
            await updatePassword(user, newPassword);
            setSuccess("Lösenordet har uppdaterats framgångsrikt efter om-inloggning.");
          } catch (reauthError) {
            setError("Om-inloggning misslyckades: " + reauthError.message);
          }
        } else {
          setError("Om-inloggning krävs för att uppdatera lösenordet.");
        }
      } else {
        setError("Ett fel uppstod vid uppdatering av lösenordet: " + error.message);
      }
    }
  };
  
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSaveSettings = async (e) => {
    e.preventDefault();
  
    if (!userData || !userData.attributes) {
      setError("User data or attributes are undefined");
      return;
    }

    
  // Hämta referensen antingen via userData.number eller genom att hitta kundens dokument med authUID
  let userDocRef;
  if (userData.number) {
    userDocRef = doc(db, 'customers', userData.number);
  } else {
    // Skapa en query för att hitta kunddokumentet där userData.authUID matchar user.uid
    const q = query(collection(db, 'customers'), where('authUID', '==', user.uid));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      userDocRef = querySnapshot.docs[0].ref; // Använd första träffen om flera dokument hittas
    } else {
      setError("Ingen kund hittades med det angivna authUID.");
      return;
    }
  }

  
    // Hämta de nuvarande attributen
    let marketingAttribute = userData.attributes.find(attr => attr.id === 'MARKNAD');
    let catalogAttribute = userData.attributes.find(attr => attr.id === 'KATALOG');
  
    // Uppdatera värdena endast om de finns
    if (marketingAttribute) {
      marketingAttribute.value = emailConsent ? '1' : '0';
    } else {
      userData.attributes.push({ id: 'MARKNAD', value: emailConsent ? '1' : '0' });
    }
  
    if (catalogAttribute) {
      catalogAttribute.value = catalogPreference;
    } else {
      userData.attributes.push({ id: 'KATALOG', value: catalogPreference });
    }
  
  
    const updatedUserData = { ...userData, attributes: userData.attributes };
  
    try {
      // Uppdatera Firestore
      const userDocRef = doc(db, 'customers', userData.number || user.uid); // Använd userData.uid eller user.uid beroende på tillgänglighet
      await updateDoc(userDocRef, { attributes: userData.attributes });
      setSuccess("Användarinställningar har uppdaterats framgångsrikt.");
  
      // Kryptera uppdaterade användardata innan du sparar i sessionStorage
      const encryptedUserData = encryptData(updatedUserData);
      sessionStorage.setItem('userData', encryptedUserData); // Spara krypterade användaruppgifter
      setUserData(updatedUserData); // Uppdatera UserContext med dekrypterad data
    } catch (error) {
      setError("Ett fel uppstod vid uppdatering av användarinställningar: " + error.message);
      console.error("Error updating user settings:", error);
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className='container mx-auto pt-24 mt-24 '>
      <nav className="flex p-5" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse dark:text-white">
          <li className="inline-flex items-center">
            <Link className="inline-flex items-center text-sm font-medium text-gray-700 dark:text-white hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" to="/">
              <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>Hem
            </Link>
          </li>
          <li>
            <div className="flex items-center">
              <svg className="rtl:rotate-180 w-3 h-3 text-gray-400 dark:text-white mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
              </svg>
              <Link to={`/user/${currentUser}/user-profile`}>Profil</Link>
            </div>
          </li>
        </ol>
      </nav>
   
      <form onSubmit={handleSaveSettings}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12 mx-5">
            <h1 className="leading-7 font-bold text-2xl dark:text-white text-gray-900">Din Profil</h1>
<p className='text-sm text-slate-600 leading-6 my-2'>Observera att adressfälten inte går att ändra. Detta är för er information endast. <span className='font-semibold'>önskar ni byta information? </span> 
Maila in dina ändringar till oss på: <a className='font-semibold text-green-800' href="mailto:info@gastronomileverantoren.se">info@gastronomileverantoren.se</a>
</p>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 ">
              <div className="sm:col-span-2">
                <label htmlFor="username" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                  Kundnummer:
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <input
                      type="text"
                      name="kundnummer"
                      id="kundnummer"
                      disabled
                      autoComplete="kundnummer"
                      className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white"
                      value={userData.number}
                    />
                  </div>
                </div>
              </div>
              {/* Rendera description från discountInfo om det finns */}
              {discountInfo && discountInfo.description && (
                <div className="sm:col-span-2">
                  <label htmlFor="discount" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                    Rabattbeskrivning:
                  </label>
                  <div className="mt-2">
                    <p className="block text-sm text-gray-700 dark:text-white">{discountInfo.description}</p>
                  </div>
                </div>
              )}

              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                  Kundkonto kopplad e-postadress:
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={userData.mainContact.email} />
                </div>
              </div>
            </div>
            {/* Länkade konton */}
  <LinkedAccounts customerId={userData.number} linkedAccounts={userData.accounts || []} />
          </div>

          <div className="border-b border-gray-900/10 pb-12 mx-5">
            <h2 className="text-base font-semibold leading-7 text-xl text-gray-900 dark:text-white">Företagsinformation:</h2>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                  Företagsnamn:
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={userData.name}
                    className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="first-name" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                  Kontaktperson/Referens:
                </label>
                <div className="mt-2">
                  <input
                    disabled
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className='mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-4 divide-y divide-gray-900/10'>
              <div className='px-4 py-6 bg-white shadow-sm ring-1 ring-gray-900/5 dark:ring-gray-600 sm:rounded-xl col-span-2 grid grid-cols-2 gap-x-6 gap-y-4 dark:bg-white/5 dark:text-white'>
                <div className='col-span-2'>
                  <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Huvudadress:</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-white">Er huvudsakliga address.</p>
                </div>

                <div className="col-span-2">
                  <label htmlFor="street-address" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                    Gatuadress:
                  </label>
                  <div className="mt-2">
                    <input
                      disabled
                      type="text"
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset dark:ring-gray-600 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userData.mainAddress.addressLine1} />
                  </div>
                </div>

                <div className="col-span-1 sm:col-start-1">
                  <label htmlFor="city" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                    Ort:
                  </label>
                  <div className="mt-2">
                    <input
                      disabled
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userData.mainAddress.city} />
                  </div>
                </div>

                <div className="sm:col-span-1">
                  <label htmlFor="postal-code" className="block text-sm font-medium leading-6 dark:text-white text-gray-900">
                    Postnummer:
                  </label>
                  <div className="mt-2">
                    <input
                      disabled
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userData.mainAddress.postalCode} />
                  </div>
                </div>
              </div>

              <div className='px-4 py-6 bg-white dark:bg-white/5 dark:text-white shadow-sm ring-1 ring-gray-900/5 dark:ring-gray-600 sm:rounded-xl col-span-2 grid grid-cols-2 gap-x-6 gap-y-4'>
                <div className='col_span-2'>
                  <h2 className="text-base font-semibold leading-7 dark:text-white text-gray-900">Leveransadress:</h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-white">Leveransadress för er verksamhet.</p>
                </div>

                <div className="col-span-2">
                  <label htmlFor="delivery-street-address" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                    Gatuadress:
                  </label>
                  <div className="mt-2">
                    <input
                      disabled
                      type="text"
                      name="delivery-street-address"
                      id="delivery-street-address"
                      autoComplete="delivery-street-address"
                      className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userData.deliveryAddress.addressLine1} />
                  </div>
                </div>

                <div className="sm:col-span-1 sm:col-start-1">
                  <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
                    Ort
                  </label>
                  <div className="mt-2">
                    <input
                      disabled
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="address-level2"
                      className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300  dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userData.deliveryAddress.city} />
                  </div>
                </div>

                <div className="sm:col-span-1">
                  <label htmlFor="postal-code" className="block text-sm dark:text-white font-medium leading-6 text-gray-900">
                    Postnummer:
                  </label>
                  <div className="mt-2">
                    <input
                      disabled
                      type="text"
                      name="postal-code"
                      id="postal-code"
                      autoComplete="postal-code"
                      className="dark:bg-white/5 dark:text-white block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={userData.deliveryAddress.postalCode} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="border-b border-gray-900/10 pb-12 mx-5">
            <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-gray-200">Inställningar för e-postutskick:</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
              Här kan du bestämma om du vill få information om olika saker från GastronomiLeverantören.
            </p>

            <div className="mt-10 space-y-10">
              <fieldset>
                <div className="mt-6 space-y-6">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="comments"
                        name="comments"
                        type="checkbox"
                        checked={emailConsent}
                        onChange={() => setEmailConsent(!emailConsent)}
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="comments" className="font-medium text-gray-900 dark:text-gray-200">
                        Tillåter e-postutskick
                      </label>
                      <p className="text-gray-500 dark:text-gray-400">Godkänner att GastronomiLeverantören får skicka ut epost med information om aktuella erbjudanden och kampanjer.</p>
                    </div>
                  </div>
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <select
                        id="catalog"
                        name="catalog"
                        value={catalogPreference}
                        onChange={(e) => setCatalogPreference(e.target.value)}
                        className="h-10 w-full rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      >
                        <option value="DIGITAL KATALOG">DIGITAL KATALOG</option>
                        <option value="INGEN">INGEN</option>
                        <option value="SKICKAS I PALL">SKICKAS I PALL</option>
                        <option value="FYSISK KATALOG">FYSISK KATALOG</option>
                      </select>
                    </div>
                    <div className="text-sm leading-6">
                      <label htmlFor="catalog" className="font-medium text-gray-900 dark:text-gray-200">
                        Tillåter digital katalog
                      </label>
                      <p className="text-gray-500 dark:text-gray-400">Godkänner att Gastronomileverantören får skicka produktkatalog via e-postutskick.</p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div className="mt-6 flex items-center mx-5 gap-x-6">
            <button type="button" className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200">
              Avbryt
            </button>
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Spara Inställningar
            </button>
          </div>
        </div>
      </form>
      <div className="border-b mt-20 border-gray-900/10 pb-12 mx-5">
      <h2 className=" font-bold leading-7 text-gray-900 dark:text-white text-xl">Byt Lösenord:</h2>
      <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-white">
        Här kan du uppdatera ditt lösenord.
      </p>

      <form onSubmit={handlePasswordChange} className="mt-10 space-y-6">
        <div>
        <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
        Nytt Lösenord:
      </label>
      <div className="mt-2 relative">
        <input
          id="new-password"
          name="new-password"
          type={showPassword ? "text" : "password"}
          autoComplete="new-password"
          required
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 dark:ring-gray-600 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white"
        />
        <button
          type="button"
          onClick={toggleShowPassword}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
        >
          {showPassword ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
           
            <FontAwesomeIcon icon={faEye} />
          )}
        </button>
      </div>
        </div>

        <div>
        <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
        Bekräfta Nytt Lösenord:
      </label>
      <div className="mt-2 relative">
        <input
          id="confirm-password"
          name="confirm-password"
          type={showPassword ? "text" : "password"}
          autoComplete="confirm-password"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-white/5 dark:text-white"
        />
        <button
          type="button"
          onClick={toggleShowPassword}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
        >
         {showPassword ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
           
            <FontAwesomeIcon icon={faEye} />
          )}
        </button>
          </div>
        </div>

        {error && <p className="text-red-600">{error}</p>}
        {success && <p className="text-green-600">{success}</p>}

        <div className="mt-6 flex items-center gap-x-6">
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Uppdatera Lösenord
          </button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default UserDetails;
