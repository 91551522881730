import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/authContext';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@awesome.me/kit-e012a9aa4e/icons/classic/solid';
import GastroLogga from "../imgs/Gastronomileverantoren_logotyp.png";
import { getAuth, setPersistence, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';
import { authentication } from '../firebase/firebase'; // Detta är korrekt om du exporterar getAuth(app) som "authentication"

function Login() {
  const { login, currentUser, logout, error } = useAuth();
  const [email, setEmail] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [password, setPassword] = useState('');
  const [identifier, setIdentifier] = useState(''); // Hanterar både e-post eller kundnummer
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
  
      // Sätt persistens baserat på "Kom ihåg mig"-checkbox
      if (rememberMe) {
        await setPersistence(authentication, browserLocalPersistence); // Sparar token i localStorage
      } else {
        await setPersistence(authentication, browserSessionPersistence); // Sparar token i sessionStorage
      }
  
      await login(identifier, password); // Antar att login är signInWithEmailAndPassword
    }
  

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };



  useEffect(() => {
    if (currentUser) {
      navigate('/handla');
    }
  }, [currentUser, navigate]);

  return (
    <div className='dark:bg-gray-800 dark:text-white'>
      {currentUser ? (<>
      
        <div className='container-full p-20 '>
        <h1 className="text-2xl text-center my-10 text-bold text-slate-900">Inloggad som {currentUser.displayName} - Omdirigerar till butik...</h1>
          <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
    
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
       
          <p className="text-sm text-blue-700">Du är inloggad som {currentUser.email}</p>
          <p className="mt-3 text-sm md:ml-6 md:mt-0">
           
            <button className='whitespace-nowrap font-medium text-blue-700 hover:text-blue-600' onClick={logout}>Logga ut</button>
              <span aria-hidden="true"> &rarr;</span>
            
          </p>
        </div>
      </div>
    </div>
    </div>  
    </>
      
      ) : (
        <div className="flex min-h-full flex-1 flex-col col-span-2 container mx-auto w-full md:w-1/2 justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto md:w-full sm:max-w-lg">
          <img
            className="mx-auto h-64 w-auto"
            src={GastroLogga}
            alt="GastronomiLeverantören"
          />
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-white">
           Logga in
          </h2>
        </div>
        
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm"></div>
        <form onSubmit={handleLogin}>
          <div className="mt-2">
          <label htmlFor="identifier">E-postadress eller Kundnummer</label>
        <input
          type="text"
          id="identifier"
          autoComplete="username" // Lämpligt för både e-post och kundnummer
          className="dark:bg-transparent dark:ring-gray-600 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-transparent dark:border-gray-600"
          placeholder="E-post eller kundnummer"
          value={identifier}
          onChange={(e) => setIdentifier(e.target.value)} // Uppdaterar identifieringsvärdet
        />
      </div>
      <div className="mt-2 relative">
          <input           type={showPassword ? "text" : "password"}
 autoComplete="current-password" name='password' className="dark:bg-transparent dark:ring-gray-600 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm dark:text-white sm:leading-6" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Lösenord" />
          <button
          type="button"
          onClick={toggleShowPassword}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"
        >
          {showPassword ? (
            <FontAwesomeIcon icon={faEyeSlash} />
          ) : (
           
            <FontAwesomeIcon icon={faEye} />
          )}
        </button></div>
          
          <button className='rounded-md bg-white px-3 py-2 text-sm font-semibold my-5 text-gray-900 shadow-sm ring-1 ring-inset dark:border-gray-600 hover:bg-gray-50 dark:bg-slate-600 dark:text-gray-200' type="submit">Logga in</button>
          <div className="mt-2">
  <label className="flex items-center">
    <input
      type="checkbox"
      checked={rememberMe}
      onChange={(e) => setRememberMe(e.target.checked)}
      className="mr-2"
    />
    Kom ihåg mig
  </label>
</div>{error &&  <div className="rounded-md bg-red-50 dark:bg-transparent  p-4">
      <div className="flex">
        <div className="flex-shrink-0">
        
        </div>
        <div className="ml-3 ">
          <h3 className="text-sm font-medium text-red-800 dark:text-red-500">Något är fel vid inloggningen:</h3>
          <div className="mt-2 text-sm text-red-700 dark:text-red-500">
            <ul role="list" className="list-disc space-y-1 pl-5">
              <li>{error}</li>
             
            </ul>
          </div>
        </div>
      </div>
    </div>}
        </form>
        
          <p className="mt-10 text-center dark:text-gray-300 text-sm text-gray-600">
            Glömt lösenordet?{' '}
            <Link to="/forgot-password" className="font-semibold leading-6 text-yellow-600 hover:text-yellow-800">
              Klicka här för att återställa ditt lösenord
            </Link>
          </p>
        </div>
     
      )}
    </div>
  );
}

export default Login;