import React, { useEffect, useRef, useState } from "react";
import Category from "./Category/Category";
import SalesCategory from "./Category/SalesCategory";
import Suppliers from "./Suppliers";
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useAuth } from "../context/authContext";

const Sidebar = ({ isOpen, toggleSidebar, handleChange, openSidebar, closeSidebar, isDesktop }) => {
  const sidebarRef = useRef(null);
  const { currentUser } = useAuth();

  return (
    <>
      <aside
        ref={sidebarRef}
        id="separator-sidebar"
        className={`fixed left-0 top-0 md:top-36 md:z-40 z-50 md:w-72 w-full h-screen transition-transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 py-8  overflow-y-auto bg-gray-50 dark:bg-gray-800 dark:text-white">
          <div className="flex justify-between items-center"><div className="flex items-center"><AiOutlineClose
            className="bg-slate-600 cursor-pointer p-1 m-3 text-white block"
            size={30}
            onClick={closeSidebar} 
          />
          Stäng
        
          </div>
          <h1 className="text-xl mr-10 font-semibold">Sortiment</h1>
          </div>
          {currentUser && (
            <SalesCategory handleChange={handleChange} />
          )}
       <Category handleChange={handleChange} onClick={toggleSidebar} isDesktop={isDesktop} />
       <div className="border-b dark:border-b-gray-700">
        <button className="sidebar-label-container flex items-center w-full justify-between text-left p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
          
          <AiOutlineRight size="10" />
        </button>
      </div>
<Suppliers handleChange={handleChange} onClick={toggleSidebar} isDesktop={isDesktop} />

        </div>
      </aside>
    </>
  );
};

export default Sidebar;
