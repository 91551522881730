import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom'; 
import Sidebar from './Sidebar/Sidebar';
import Layout from './Layout/Layout';
import ProductDetail from './Products/ProductDetail';
import Header from './Layout/Header';
import Cart from './components/Cart';
import { useProductContext } from './context/ProductContext';

import CategoryPage from './pages/CategoryPage';

import Login from './Users/Login';
import UserDetails from './Users/Profile/userDetails';
import OrderHistory from './Users/Profile/OrderHistory';
import OrderDetails from './Users/Profile/Orders/OrderDetails';
import CheckoutPage from './pages/CheckoutPage';
import SupplierPage from './pages/SupplierPage';
import SalesPage from './pages/SalesPage';
import BottomNav from './Navigation/BottomNav';
import OrderSuccess from './pages/OrderSuccess';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ResetPassword from './Users/ResetPassword';

import Kampanj from './components/Kampanj';
import ResellerApplicationForm from './Users/ResellerApplicationForm';
import ShopStart from './pages/StartPage';
import Main from './pages/Main';
import PromotionPage from './pages/PromotionPage';
import Footer from './Layout/Footer';
import About from './pages/About';
import SearchResultsPage from './pages/SearchResultsPage';
import NotFound from './pages/NoPage';
import ScrollToTop from './components/scrollToTop';
import Klippvaror from './Products/Klippvaror';
import { useUserContext } from './context/userContext';
import PromotionalEditForm from './components/PromotionalEditForm';
import Integritetspolicy from './pages/Integritetspolicy';
import TermsAndConditions from './pages/termsAndConditions';
import CartPage from './pages/CartPage';
import HighlightedProductsEditForm from './components/HighlighedProductsEditForm';
import KlippDetail from './Products/KlippDetail';

const App = () => {
  const { products, discountedProducts } = useProductContext();
  const { role } = useUserContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);  // Stängd som standard
  const [isManuallyToggled, setIsManuallyToggled] = useState(false);
  const location = useLocation(); 
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [query, setQuery] = useState('');
  const [productsToUse, setProductsToUse] = useState(products);
  // Kontrollera skärmstorlek (mobil eller desktop)
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Produktrelaterade rutter
  const productRelatedRoutes = [
    '/handla',
    '/search',
    '/supplier',
    '/specialcat',
    '/cat',
    '/kampanj',
    '/klippvaror',
  ];


  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleChange = (event, category) => {
    setSelectedCategory(category);
  };

  const handleSupplierChange = (event, supplier) => {
    setSelectedSupplier(supplier);
  };

  const handleClick = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Hantera sidomenyn baserat på rutt och skärmstorlek
 // Hantera sidomenyn baserat på rutt och skärmstorlek
 useEffect(() => {
  const isProductPage = productRelatedRoutes.some((route) => location.pathname.includes(route));

  if (isDesktop && isProductPage && !isManuallyToggled) {
    setIsSidebarOpen(true);  // Öppna sidomenyn automatiskt på produktrelaterade sidor på desktop
  } else if (isDesktop && !isProductPage && !isManuallyToggled) {
    setIsSidebarOpen(false);  // Stäng sidomenyn när vi lämnar produktrelaterade sidor på desktop
  } else if (!isDesktop && !isManuallyToggled) {
    setIsSidebarOpen(false);  // Stäng sidomenyn på mobil som standard
  }
}, [location.pathname, isDesktop, isManuallyToggled]);

  // Hantera manuell toggle (används av BottomNav och andra komponenter som öppnar manuellt)
  const handleManualToggle = () => {
    if (!isDesktop) { // Kolla om vi är på mobil
      setIsManuallyToggled(true);  // Markera att togglingen är manuell
      setIsSidebarOpen((prevState) => !prevState);  // Toggla sidomenyn
    }
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false); // Stäng sidomenyn
    setIsManuallyToggled(false); // Återställ manuell toggle-status
  };
  
  
  const openSidebar = () => {
    setIsSidebarOpen(true); // Stäng sidomenyn
    setIsManuallyToggled(true); // Återställ manuell toggle-status
  };

  // Funktion för att stänga sidomenyn vid navigation
  const handleNavigation = () => {
    if (!isDesktop && isSidebarOpen) { // Stäng bara om vi är på mobil och menyn är öppen
      setIsSidebarOpen(false);  // Stäng sidomenyn
      setIsManuallyToggled(false);  // Återställ manuell toggle
    }
  };


useEffect(() => {
  setProductsToUse(discountedProducts.length > 0 ? discountedProducts : products);
// console.log("App: productsToUse uppdaterad", products);
}, [discountedProducts, products]); // Lägg till beroenden för att se till att `productsToUse` uppdateras
  return (
    <div className=''>
      <ScrollToTop />
      <Header
        toggleSidebar={handleManualToggle}
        isSidebarOpen={isSidebarOpen}
        openSidebar={openSidebar}
      />
      <Sidebar
        isOpen={isSidebarOpen}
        handleChange={handleChange}
        toggleSidebar={handleManualToggle}
        isManuallyToggled={isManuallyToggled}
        openSidebar={openSidebar}
        closeSidebar={closeSidebar}
        isDesktop={isDesktop} // Skicka `isDesktop` som en prop
      />
      <Cart />
      <Routes>
        <Route element={<Layout isSidebarOpen={isSidebarOpen} />}>
          <Route index path="/" element={<Main />} />
          <Route path="/handla" element={<ShopStart />} />
          <Route path='/om-foretaget' element={<About />} />
          <Route path="/bli-aterforsaljare" element={<ResellerApplicationForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/varukorg" element={<CartPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/checkout/order-success" element={<OrderSuccess />} />
          <Route path='/forgot-password' element={<ResetPassword />} />
          <Route path='/reset-password' element={<ResetPasswordPage />} />
          <Route path='/allmanna-villkor' element={<TermsAndConditions />} />
          <Route path='/integritetspolicy' element={<Integritetspolicy />} />
          <Route path="/search" element={<SearchResultsPage />} />
          <Route path="/admin/promotional-management" element={<PromotionalEditForm role={role} />} />
          <Route path="/admin/highlight-management" element={<HighlightedProductsEditForm role={role} />} />
          <Route path="/user/:displayName/user-profile" element={<UserDetails />} />
          <Route path="/user/:displayName/order-history" element={<OrderHistory />} />
          <Route path="/user/:displayName/order/:orderId" element={<OrderDetails products={discountedProducts} />} />
          <Route path="/cat/:mainCategorySlug/:subCategorySlug?" element={<CategoryPage />} />
          <Route path="/supplier/:selectedSupplier" element={<SupplierPage products={productsToUse} />} />
          <Route path="/specialcat/:selectedSalesCategory" element={<SalesPage products={productsToUse} />} />
        
          <Route path="/klippvara/:inventoryNumber" element={<KlippDetail />} />
          <Route path="/cat/:mainCategorySlug/product/:inventoryNumber" element={<ProductDetail />} />
          <Route path="/kampanj/:discountCode" element={<Kampanj />} />
          <Route path="/kampanjer/:slug" element={<PromotionPage />} />
          <Route path="/klippvaror" element={<Klippvaror />} />
          <Route path="/klippvaror/:inventoryNumber" element={<KlippDetail />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      <BottomNav toggleSidebar={handleManualToggle} closeSidebar={closeSidebar} />
      <Footer isSidebarOpen={isSidebarOpen} />
    </div>
  );
};


export default App;
